/**
 *
 * Asynchronously loads the component for ConfirmRegistrationPage
 *
 */

import { lazyLoad } from 'utils/loadable';

const ConfirmRegistrationPage = lazyLoad(
	() => import('./index'),
	module => module.ConfirmRegistrationPage
);

export default ConfirmRegistrationPage;
