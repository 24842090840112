import { useContext } from 'react';
import { RootStoreType } from '../stores/root-store/root.store';
import { RootStoreContext } from '../stores/root-store/root-store.context';

export const useRootStore = (): RootStoreType => {
	const store = useContext(RootStoreContext);
	if (!store) {
		throw new Error('The RootStoreContext is not provided using the <RootStoreProvider />.');
	}
	return store;
};
