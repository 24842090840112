import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';

// add system locale by platform
// https://github.com/iamkun/dayjs/tree/dev/src/locale
import 'dayjs/locale/de';
import 'dayjs/locale/en';

// add base plugins
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

/**
 * Switch dayjs locale
 * Try match the right language from i18next
 */
const setLocaleByI18n = (language: string): string => {
	let lng: string;
	switch (language) {
		case 'jp':
			lng = 'ja';
			break;
		case 'kr':
			lng = 'ko';
			break;
		case 'cn':
			lng = 'zh-cn';
			break;
		case 'cz':
			lng = 'cs';
			break;
		case 'tr':
			lng = 'tk';
			break;
		case 'us':
			lng = 'en';
			break;
		default:
			lng = language;
			break;
	}

	return dayjs.locale(lng);
};

const formatTime = (time?: string | Date, format?: string, withTime = false): string => {
	if (!time) return '';
	const formatString = format ? format : withTime ? 'DD.MM.YYYY HH:MM' : 'DD.MM.YYYY';
	return dayjs(time).format(formatString);
};

export { dayjs, setLocaleByI18n, formatTime };
