// Create the measurement node
const scrollDiv = document.createElement('div');
scrollDiv.setAttribute('style', 'width: 100px;height: 100px;overflow: scroll;position: absolute;top: -9999px;');
document.body.appendChild(scrollDiv);

// Get the scrollbar width
const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
if (scrollbarWidth === 0) {
	document.body.className += 'mobile-scrollbar';
}

// Delete the DIV
document.body.removeChild(scrollDiv);

export {};
