/**
 *
 * Asynchronously loads the component for TestAndFixPage
 *
 */

import { lazyLoad } from 'utils/loadable';

const TestAndFixPage = lazyLoad(
	() => import('./index'),
	module => module.TestAndFixPage
);

export default TestAndFixPage;
