import { SandboxEnvironment, SelectOption } from 'types/app.typings';

export const defaultBaselineProtocolFormTestResults = [
	{ label: 'Passed', value: 'pass', disabled: false, isSelected: false },
	{ label: 'Failed', value: 'fail', disabled: false, isSelected: false },
];

export const defaultBaselineProtocolFormYesNo = [
	{ label: 'Yes', value: 'yes', disabled: false, isSelected: false },
	{ label: 'No', value: 'no', disabled: false, isSelected: false },
];

export const defaultSupportInfo = [
	{ country: 'Austria', code: 'AT', phone: '(+43) 720 204503', serviceTime: 'Mo - Sa: 08:00 - 20:00 CEST' },
	{ country: 'Belgium', code: 'BE', phone: '(+32) 2 4007623', serviceTime: 'Mo - Sa: 08:00 - 20:00 CEST' },
	{
		country: 'Estonia',
		code: 'EE',
		phone: `(+372) 800 0112005 &emsp; (from Estonia) <br> (+49) 5241 9981123 &emsp; (from other countries)`,
		serviceTime: 'Mo - Sa: 08:00 - 20:00 CEST',
	},
	{ country: 'Finland', code: 'FI', phone: '(+358) 9 85619028', serviceTime: 'Mo - Sa: 08:00 - 20:00 CEST' },
	{ country: 'France', code: 'FR', phone: '(+33) 1 73443289', serviceTime: 'Mo - Sa: 08:00 - 20:00 CEST' },
	{ country: 'Germany', code: 'DE', phone: '(+49) 5241 52 74727', serviceTime: 'Mo - Sa: 08:00 - 20:00 CEST' },
	{ country: 'Italy', code: 'IT', phone: '(+39) 800 789580', serviceTime: 'Mon – Sat 8:00 – 20:00 CET' },
	{ country: 'Hungary', code: 'HU', phone: '(+36) 61 6555311', serviceTime: 'Mo - Sa: 08:00 - 20:00 CEST' },
	{ country: 'Latvia', code: 'LV', phone: '(+371) 6 0001587', serviceTime: 'Mo - Sa: 08:00 - 20:00 CEST' },
	{ country: 'Netherlands', code: 'NL', phone: '(+31) 20 7994884', serviceTime: 'Mo - Sa: 08:00 - 20:00 CEST' },
	{
		country: 'Norway',
		code: 'NO',
		phone: `(+47) 800 12714 &emsp;&emsp;&nbsp; (from Norway) <br> (+49) 5241 9981121 &ensp; (from other countries)`,
		serviceTime: 'Mo - Sa: 08:00 - 20:00 CEST',
	},
	{
		country: 'Poland',
		code: 'PL',
		phone: `(+48) 800 1216779 &emsp;&ensp; (from Poland) <br> (+49) 5241 9981122 &emsp; (from other countries)`,
		serviceTime: 'Mo - Sa: 08:00 - 20:00 CEST',
	},
	{ country: 'Portugal', code: 'PT', phone: '(+351) 2 11451593', serviceTime: 'Mo - Sa: 08:00 - 20:00 CEST' },
	{ country: 'Romania', code: 'RO', phone: '(+40) 21 5291789', serviceTime: 'Mo - Sa: 08:00 - 20:00 CEST' },
	{ country: 'Slovakia', code: 'SK', phone: '(+421) 2 33329160', serviceTime: 'Mo - Sa: 08:00 - 20:00 CEST' },
	{ country: 'Spain', code: 'ES', phone: '(+34) 91 4199620', serviceTime: 'Mo - Sa: 08:00 - 20:00 CEST' },
	{ country: 'United Kingdom', code: 'UK', phone: '(+44) 20 78941510', serviceTime: 'Mo - Sa: 08:00 - 20:00 CEST' },
];

export const defaultContactReasons = [
	{
		label: 'Access/Login',
		value: 'Access/Login',
		disabled: false,
		isSelected: false,
	},
	{
		label: 'Alert',
		value: 'Alert',
		disabled: false,
		isSelected: false,
	},
	{
		label: 'Baseline testing',
		value: 'Baseline testing',
		disabled: false,
		isSelected: false,
	},
	{
		label: 'Certificate',
		value: 'Certificate',
		disabled: false,
		isSelected: false,
	},
	{
		label: 'Documentation',
		value: 'Documentation',
		disabled: false,
		isSelected: false,
	},
	{
		label: 'Endpoints',
		value: 'Endpoints',
		disabled: false,
		isSelected: false,
	},
	{
		label: 'Error messages',
		value: 'Error messages',
		disabled: false,
		isSelected: false,
	},
	{
		label: 'Incident',
		value: 'Incident',
		disabled: false,
		isSelected: false,
	},
	{
		label: 'NMVS Web GUI',
		value: 'NMVS Web GUI',
		disabled: false,
		isSelected: false,
	},
	{
		label: 'SOAP UI request',
		value: 'SOAP UI request',
		disabled: false,
		isSelected: false,
	},
	{
		label: 'Test packs',
		value: 'Test packs',
		disabled: false,
		isSelected: false,
	},
	{
		label: 'WSDL files',
		value: 'WSDL files',
		disabled: false,
		isSelected: false,
	},
	{
		label: 'General questions',
		value: 'General questions',
		disabled: false,
		isSelected: false,
	},
	{
		label: 'Other',
		value: 'Other',
		disabled: false,
		isSelected: false,
	},
];

export const defaultApplications = [
	{
		label: 'Sandbox',
		value: 'sandbox',
		disabled: false,
		isSelected: false,
	},
	{
		label: 'Integrated quality environment',
		value: 'iqe',
		disabled: false,
		isSelected: false,
	},
	{
		label: 'Production environment',
		value: 'prd',
		disabled: false,
		isSelected: false,
	},
];

export const defaultContactPriorities = [
	{
		label: 'Important',
		value: 'important',
		disabled: false,
		isSelected: false,
	},
	{
		label: 'Medium',
		value: 'medium',
		disabled: false,
		isSelected: false,
	},
	{
		label: 'Low',
		value: 'low',
		disabled: false,
		isSelected: false,
	},
];

export const defaultEndCustomers: SelectOption[] = [
	{ label: 'Wholesaler', value: 'WHOLESALER', disabled: false, isSelected: false },
	{ label: 'Hospital', value: 'HOSPITAL', disabled: false, isSelected: false },
	{ label: 'Pharmacy', value: 'PHARMACY', disabled: false, isSelected: false },
];

export const defaultSalutations: SelectOption[] = [
	{
		value: 'ms',
		label: 'Ms.',
		disabled: false,
		isSelected: false,
	},
	{
		value: 'mr',
		label: 'Mr.',
		disabled: false,
		isSelected: false,
	},
];

export const defaultSandboxes = [
	{
		value: SandboxEnvironment.ONE,
		label: 'Sandbox 1 (Shared-Int-1)',
		disabled: false,
		isSelected: false,
	},
];
