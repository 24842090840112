/**
 *
 * Asynchronously loads the component for BaselineProtocolPage
 *
 */

import { lazyLoad } from 'utils/loadable';

const BaselineProtocolPage = lazyLoad(
	() => import('./index'),
	module => module.BaselineProtocolPage
);

export default BaselineProtocolPage;
