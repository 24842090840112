/* eslint-disable no-console */
import { toJS } from 'mobx';
import { applySnapshot, flow, getParent, Instance, types } from 'mobx-state-tree';
import { RootStore } from 'stores';
import { ExtraCountryRegistrationDto } from 'utils/dtos/extra-country-registration.dto';
import {
	AccountExistedError,
	AccountRejectionError,
	PendingApprovalError,
	PendingEmailConfirmationError,
	PendingSpFetchError,
	RegistrationError,
	UnknownEmailError,
	UnsupportedCountryError,
	ValidationError,
} from 'utils/errors';
import { LoginError } from 'utils/errors/Login.error';
import {
	AuthenticatedUser,
	ObservableState,
	RequestPasswordReset,
	ResetPasswordState,
	UserCredentials,
	UserProfile,
} from 'types/app.typings';

export const SelectOptionType = types.model('SelectOptionType', {
	label: types.string,
	value: types.string,
	disabled: types.boolean,
	isSelected: types.boolean,
});

export const SupportInfoOptionType = types.model('SupportInfoOptionType', {
	country: types.string,
	code: types.string,
	phone: types.string,
	serviceTime: types.string,
});

export const ContactFormAnnouncementType = types.model('ContactFormAnnouncementType', {
	intent: types.string,
	text: types.string,
	authenticated: types.string,
	enabled: types.boolean,
});

export type SelectOptionInstance = Instance<typeof SelectOptionType>;
export type SupportInfoOptionInstance = Instance<typeof SupportInfoOptionType>;
export type ContactFormAnnouncementInstance = Instance<typeof ContactFormAnnouncementType>;

export const ApplicationStore = types
	.model('ApplicationStore', {
		_maintenanceMode: types.boolean,
		_failedLoginAttempts: types.number,
		_contactFormAnnouncement: types.maybeNull(ContactFormAnnouncementType),

		_loginError: types.maybeNull(types.frozen()),
		_resetPwdError: types.maybeNull(types.frozen()),
		_registrationError: types.maybeNull(types.frozen()),
		_confirmEmailError: types.maybeNull(types.frozen()),
		_contactSupportError: types.maybeNull(types.frozen()),
		_changeActiveCountryError: types.maybeNull(types.frozen()),
		_extraCountryRegistrationError: types.maybeNull(types.frozen()),
		_requestSandboxUsernameError: types.maybeNull(types.frozen()),
		_getUserProfileError: types.maybeNull(types.frozen()),
		_subscribeNewsletterError: types.maybeNull(types.frozen()),
		_maintenanceModeError: types.maybeNull(types.frozen()),

		_loginState: types.enumeration(Object.values(ObservableState)),
		_forgotPwdSubmitState: types.enumeration(Object.values(ObservableState)),
		_resetPwdState: types.enumeration(Object.values(ObservableState)),
		_resetPwdUIState: types.enumeration(Object.values(ResetPasswordState)),
		_registrationState: types.enumeration(Object.values(ObservableState)),
		_registrationUIState: types.enumeration(Object.values(ObservableState)),
		_confirmEmailState: types.enumeration(Object.values(ObservableState)),
		_contactSupportState: types.enumeration(Object.values(ObservableState)),
		_changeActiveCountryState: types.enumeration(Object.values(ObservableState)),
		_extraCountryRegistrationState: types.enumeration(Object.values(ObservableState)),
		_requestSandboxUsernameState: types.enumeration(Object.values(ObservableState)),
		_getUserProfileState: types.enumeration(Object.values(ObservableState)),
		_subscribeNewsletterState: types.enumeration(Object.values(ObservableState)),
		_maintenanceModeState: types.enumeration(Object.values(ObservableState)),

		endCustomerOptions: types.array(SelectOptionType),
		supportInfoOptions: types.array(SupportInfoOptionType),
		salutationOptions: types.array(SelectOptionType),
		sandboxOptions: types.array(SelectOptionType),
		contactReasonOptions: types.array(SelectOptionType),
		contactPriorityOptions: types.array(SelectOptionType),
		applicationOptions: types.array(SelectOptionType),
		baselineProtocolFormTestResultOptions: types.array(SelectOptionType),
		baselineProtocolFormYesNoOptions: types.array(SelectOptionType),
	})
	.views(self => ({
		get isMaintenance() {
			return self._maintenanceMode;
		},
		get failedLoginAttempts() {
			return self._failedLoginAttempts;
		},
		get contactFormAnnouncement() {
			return self._contactFormAnnouncement;
		},
		// OP-in-Progress queries
		get isLoggingIn() {
			return self._loginState === ObservableState.PENDING;
		},
		get isResettingPwd() {
			return self._resetPwdState === ObservableState.PENDING;
		},
		get isRegistering() {
			return self._registrationState === ObservableState.PENDING;
		},
		get isConfirmingEmail() {
			return self._confirmEmailState === ObservableState.PENDING;
		},
		get isRegisteringExtraCountry() {
			return self._extraCountryRegistrationState === ObservableState.PENDING;
		},
		get isContactingSupport() {
			return self._contactSupportState === ObservableState.PENDING;
		},
		get isRequestingSandboxUsername() {
			return self._requestSandboxUsernameState === ObservableState.PENDING;
		},
		get isGettingUserProfile() {
			return self._getUserProfileState === ObservableState.PENDING;
		},
		get isSubscribingNewsletter() {
			return self._subscribeNewsletterState === ObservableState.PENDING;
		},
		get isGettingMaintenanceMode() {
			return self._maintenanceModeState === ObservableState.PENDING;
		},
		// HAS ERROR queries
		get hasLoginError() {
			return self._loginState === ObservableState.ERROR;
		},
		get hasResetPwdError() {
			return self._resetPwdState === ObservableState.ERROR;
		},
		get hasRegistrationError() {
			return self._registrationState === ObservableState.ERROR;
		},
		get hasConfirmEmailError() {
			return self._confirmEmailState === ObservableState.ERROR;
		},
		get hasExtraCountryRegistrationError() {
			return self._extraCountryRegistrationState === ObservableState.ERROR;
		},
		get hasContactSupportError() {
			return self._contactSupportState === ObservableState.ERROR;
		},
		get hasRequestSandboxUsernameError() {
			return self._requestSandboxUsernameState === ObservableState.ERROR;
		},
		get hasGettingUserProfileError() {
			return self._getUserProfileState === ObservableState.ERROR;
		},
		get hasSubscribeNewsletterError() {
			return self._subscribeNewsletterState === ObservableState.ERROR;
		},
		get hasMaintenanceModeError() {
			return self._maintenanceModeError === ObservableState.ERROR;
		},
		// ERROR queries
		get loginError() {
			return self._loginError;
		},
		get resetPwdError() {
			return self._resetPwdError;
		},
		get registrationError() {
			return self._registrationError;
		},
		get confirmEmailError() {
			return self._confirmEmailError;
		},
		get extraCountryRegistrationError() {
			return self._extraCountryRegistrationError;
		},
		get contactSupportError() {
			return self._contactSupportError;
		},
		get requestSandboxUsernameError() {
			return self._requestSandboxUsernameError;
		},
		get getUserProfileError() {
			return self._getUserProfileError;
		},
		get subscribeNewsletterError() {
			return self._subscribeNewsletterError;
		},
		get maintenanceModeError() {
			return self._maintenanceModeError;
		},
		// GET Observable Promise State queries
		get loginState() {
			return self._loginState;
		},
		get forgotPwdSubmitState() {
			return self._forgotPwdSubmitState;
		},
		get resetPwdState() {
			return self._resetPwdState;
		},
		get registrationState() {
			return self._registrationState;
		},
		get registrationUIState() {
			return self._registrationUIState;
		},
		get resetPwdUIState() {
			return self._resetPwdUIState;
		},
		get confirmEmailState() {
			return self._confirmEmailState;
		},
		get extraCountryRegistrationState() {
			return self._extraCountryRegistrationState;
		},
		get contactSupportState() {
			return self._contactSupportState;
		},
		get requestSandboxUsernameState() {
			return self._requestSandboxUsernameState;
		},
		get getUserProfileState() {
			return self._getUserProfileState;
		},
		get subscribeNewsletterState() {
			return self._subscribeNewsletterState;
		},
		get maintenanceModeState() {
			return self._maintenanceModeState;
		},
		// Get various SELECT OPTIONS
		get salutations() {
			return self.salutationOptions;
		},
		get sandboxes() {
			return self.sandboxOptions;
		},
		get contactReasons() {
			return self.contactReasonOptions;
		},
		get contactPriorities() {
			return self.contactPriorityOptions;
		},
		get applications() {
			return self.applicationOptions;
		},
		get endCustomers() {
			return toJS(self.endCustomerOptions).sort(function (a: SelectOptionInstance, b: SelectOptionInstance) {
				if (a.label > b.label) return 1;
				if (a.label < b.label) return -1;
				return 0;
			});
		},
		get supportInfo() {
			return toJS(self.supportInfoOptions).sort(function (a: SupportInfoOptionInstance, b: SupportInfoOptionInstance) {
				if (a.country > b.country) return 1;
				if (a.country < b.country) return -1;
				return 0;
			});
		},
		get baselineProtocolFormTestResults() {
			return self.baselineProtocolFormTestResultOptions;
		},
		get baselineProtocolFormYesNo() {
			return self.baselineProtocolFormYesNoOptions;
		},
		// GET root store
		get rootStore(): any {
			return getParent<typeof RootStore>(self);
		},
	}))
	.actions(self => ({
		initialize(data: any) {
			applySnapshot(self, {
				...data,
			});
		},
		login: flow<AuthenticatedUser, [data: UserCredentials]>(function* (data: UserCredentials) {
			self._loginState = ObservableState.PENDING;
			try {
				const user = yield self.rootStore.api.login(data);
				self._failedLoginAttempts = 0;
				self._loginState = ObservableState.DONE;
				return user;
			} catch (error: any) {
				console.error('Unable to sign in', error);
				if (ValidationError.isValidationErrorLike(error)) {
					self._loginError = ValidationError.createFromValidationErrorLike(error.response.data);
				} else if (LoginError.isEmailUnconfirmed(error)) {
					self._loginError = new PendingEmailConfirmationError();
				} else if (LoginError.isPendingFetch(error)) {
					self._loginError = new PendingSpFetchError();
				} else if (LoginError.isAccountUnapproved(error)) {
					self._loginError = new PendingApprovalError();
				} else if (LoginError.isAccountRejected(error)) {
					self._loginError = new AccountRejectionError(error.reason, error.message);
				} else if (LoginError.isUserCredentialsInvalid(error)) {
					self._loginError = new LoginError(error.reason, error.message);
				} else if (error.reason || error.message) {
					self._loginError = new LoginError(error.reason || 'unknown', error.message);
				} else if (error.status && error.status === 504) {
					// service unavailable
					self._loginError = error;
				} else {
					self._loginError = {
						message: 'Unknown error',
						originalError: error,
					};
				}
				self._failedLoginAttempts++;
				self._loginState = ObservableState.ERROR;
			}
		}),
		initForgotPassword: flow<void, [data: RequestPasswordReset, captcha: string]>(function* (
			data: RequestPasswordReset,
			captcha: string
		) {
			self._resetPwdState = ObservableState.PENDING;
			try {
				yield self.rootStore.api.requestPasswordReset(data, captcha);
				self._resetPwdState = ObservableState.DONE;
				self._forgotPwdSubmitState = ObservableState.DONE;
			} catch (e: any) {
				console.error('Unable to request password reset', e);
				if (ValidationError.isValidationErrorLike(e)) {
					self._resetPwdError = ValidationError.createFromValidationErrorLike(e);
				} else if (e.message === 'User not exists') {
					self._resetPwdError = new UnknownEmailError();
				} else {
					self._resetPwdError = e;
				}
				self._resetPwdState = ObservableState.ERROR;
				self._forgotPwdSubmitState = ObservableState.ERROR;
			}
		}),
		resetPassword: flow<void, [token: string, password: string]>(function* (token: string, password: string) {
			self._resetPwdState = ObservableState.PENDING;
			try {
				yield self.rootStore.api.resetPassword(token, password);
				self._resetPwdState = ObservableState.DONE;
				self._resetPwdUIState = ResetPasswordState.RESET_SUCCESS;
			} catch (e: any) {
				console.error('Unable to reset password', e);
				if (e.reason && e.reason === ResetPasswordState.PASSWORD_POLICY_UNMET) {
					self._resetPwdState = ObservableState.DONE;
					self._resetPwdUIState = ResetPasswordState.PASSWORD_POLICY_UNMET;
				} else if (e.reason && e.reason === ResetPasswordState.ACCOUNT_NOT_EXISTED) {
					self._resetPwdState = ObservableState.DONE;
					self._resetPwdUIState = ResetPasswordState.ACCOUNT_NOT_EXISTED;
				} else {
					self._resetPwdState = ObservableState.ERROR;
					self._resetPwdError = e;
				}
			}
		}),
		verifyPasswordResetToken: flow<void, [token: string]>(function* (token: string) {
			self._resetPwdState = ObservableState.PENDING;
			try {
				yield self.rootStore.api.verifyPasswordResetToken(token);
				self._resetPwdState = ObservableState.DONE;
				self._resetPwdUIState = ResetPasswordState.TOKEN_VALID;
			} catch (e: any) {
				console.error('Unable to verify password reset token', e);
				if (e.reason && e.reason === ResetPasswordState.TOKEN_INVALID) {
					self._resetPwdState = ObservableState.DONE;
					self._resetPwdUIState = ResetPasswordState.TOKEN_INVALID;
				} else {
					self._resetPwdState = ObservableState.ERROR;
					self._resetPwdError = e;
				}
			}
		}),
		confirmEmail: flow<void, [email: string, token: string]>(function* (email: string, token: string) {
			self._confirmEmailState = ObservableState.PENDING;
			try {
				yield self.rootStore.api.confirmEmail(email, token);
				self._confirmEmailState = ObservableState.DONE;
			} catch (e: any) {
				console.error('Unable to confirm email', e);
				self._confirmEmailError = e;
				self._confirmEmailState = ObservableState.ERROR;
			}
		}),
		register: flow<boolean, [data: Record<string, any>, captcha: string]>(function* (
			data: Record<string, any>,
			captcha: string
		) {
			self._registrationState = ObservableState.PENDING;
			try {
				yield self.rootStore.api.register(data, captcha);
				self._registrationState = ObservableState.DONE;
				self._registrationUIState = ObservableState.DONE;
				return true;
			} catch (e: any) {
				console.error('Unable to send registration data', e);
				if (ValidationError.isValidationErrorLike(e)) {
					self._registrationError = ValidationError.createFromValidationErrorLike(e);
				} else if (RegistrationError.isAccountExistedError(e)) {
					self._registrationError = new AccountExistedError();
				} else if (RegistrationError.isUnsupportedCountryError(e)) {
					self._registrationError = new UnsupportedCountryError();
				} else {
					self._registrationError = e;
				}
				self._registrationState = ObservableState.ERROR;
				self._registrationUIState = ObservableState.ERROR;
				return false;
			}
		}),
		sendContactForm: flow<void, [isAuthenticated: boolean, data: Record<string, any>, captcha?: string]>(function* (
			isAuthenticated: boolean,
			data: Record<string, any>,
			captcha?: string
		) {
			self._contactSupportState = ObservableState.PENDING;
			try {
				yield self.rootStore.api.sendContactForm(isAuthenticated, data, captcha);
				self._contactSupportState = ObservableState.DONE;
			} catch (e: any) {
				console.error('Unable to send contact form', e);
				self._contactSupportError = e;
				self._contactSupportState = ObservableState.ERROR;
			}
		}),
		subscribeNewsletter: flow<void, [optIn: boolean]>(function* (optIn: boolean) {
			self._subscribeNewsletterState = ObservableState.PENDING;
			try {
				yield self.rootStore.api.subscribeNewsletter(optIn);
				self._subscribeNewsletterState = ObservableState.DONE;
			} catch (e: any) {
				console.error('Unable to update newsletter subscription', e);
				self._subscribeNewsletterError = e;
				self._subscribeNewsletterState = ObservableState.ERROR;
			}
		}),
		requestSandboxUsername: flow<boolean, [sandbox?: number]>(function* (sandbox = 1) {
			self._requestSandboxUsernameState = ObservableState.PENDING;
			try {
				yield self.rootStore.api.requestSandboxUsername(sandbox);
				self._requestSandboxUsernameState = ObservableState.DONE;
				return true;
			} catch (e: any) {
				console.error('Unable to request sandbox username', e);
				self._requestSandboxUsernameError = e;
				self._requestSandboxUsernameState = ObservableState.ERROR;
				return false;
			}
		}),
		changeSwCountry: flow<AuthenticatedUser, [code: string]>(function* (code: string) {
			self._changeActiveCountryState = ObservableState.PENDING;
			try {
				const user = yield self.rootStore.api.changeSwCountry(code);
				self._changeActiveCountryState = ObservableState.DONE;
				return user;
			} catch (e: any) {
				console.error('Unable to change country', e);
				self._changeActiveCountryError = e;
				self._changeActiveCountryState = ObservableState.ERROR;
			}
		}),
		registerExtraSwCountry: flow<AuthenticatedUser, [data: ExtraCountryRegistrationDto]>(function* (
			data: ExtraCountryRegistrationDto
		) {
			self._extraCountryRegistrationState = ObservableState.PENDING;
			try {
				const user = yield self.rootStore.api.registerExtraCountry(data);
				self._extraCountryRegistrationState = ObservableState.DONE;
				return user;
			} catch (e: any) {
				console.error('Unable to register for more countries', e);
				self._extraCountryRegistrationError = e;
				self._extraCountryRegistrationState = ObservableState.ERROR;
			}
		}),
		getUserProfile: flow<UserProfile, [email: string]>(function* (email: string) {
			self._getUserProfileState = ObservableState.PENDING;
			try {
				const profile = yield self.rootStore.api.getProfile(email);
				self._getUserProfileState = ObservableState.DONE;
				return profile;
			} catch (e: any) {
				console.error('Unable to get user profile', e);
				self._getUserProfileError = e;
				self._getUserProfileState = ObservableState.ERROR;
			}
		}),
		getMaintenanceMode: flow<void, []>(function* () {
			self._maintenanceModeState = ObservableState.PENDING;
			try {
				const mode = yield self.rootStore.api.getPortalMaintenanceMode();
				self._maintenanceMode = mode;
				self._maintenanceModeState = ObservableState.DONE;
			} catch (e: any) {
				console.error('Unable to get maintenance mode', e);
				self._maintenanceModeError = e;
				self._maintenanceModeState = ObservableState.ERROR;
			}
		}),
		getSwsContactFormAnnouncement: flow<void, []>(function* () {
			try {
				const announcement = yield self.rootStore.api.getSwsContactFormAnnouncement();
				self._contactFormAnnouncement = announcement;
			} catch (e: any) {
				console.error('Unable to get contact form announcement', e);
			}
		}),
		resetErrors() {
			self._loginError = null;
			self._resetPwdError = null;
			self._registrationError = null;
			self._confirmEmailError = null;
			self._contactSupportError = null;
			self._changeActiveCountryError = null;
			self._extraCountryRegistrationError = null;
			self._requestSandboxUsernameError = null;
			self._getUserProfileError = null;
			self._subscribeNewsletterError = null;
			self._maintenanceModeError = null;
			// states
			self._loginState = ObservableState.INIT;
			self._forgotPwdSubmitState = ObservableState.INIT;
			self._resetPwdState = ObservableState.INIT;
			self._resetPwdUIState = ResetPasswordState.INIT;
			self._registrationState = ObservableState.INIT;
			self._registrationUIState = ObservableState.INIT;
			self._confirmEmailState = ObservableState.INIT;
			self._contactSupportState = ObservableState.INIT;
			self._changeActiveCountryState = ObservableState.INIT;
			self._extraCountryRegistrationState = ObservableState.INIT;
			self._requestSandboxUsernameState = ObservableState.INIT;
			self._getUserProfileState = ObservableState.INIT;
			self._subscribeNewsletterState = ObservableState.INIT;
			self._maintenanceModeState = ObservableState.INIT;
		},
		resetLoginError() {
			self._loginError = null;
			self._loginState = ObservableState.INIT;
		},
		resetForgotPwdError() {
			self._resetPwdError = null;
			self._resetPwdState = ObservableState.INIT;
			self._forgotPwdSubmitState = ObservableState.INIT;
			self._resetPwdUIState = ResetPasswordState.INIT;
		},
		resetRegistrationError() {
			self._registrationError = null;
			self._registrationState = ObservableState.INIT;
			self._registrationUIState = ObservableState.INIT;
		},
		resetConfirmRegError() {
			self._confirmEmailError = null;
			self._confirmEmailState = ObservableState.INIT;
		},
		resetContactSupportError() {
			self._contactSupportError = null;
			self._contactSupportState = ObservableState.INIT;
		},
		resetForgotPwdUIState() {
			self._resetPwdUIState = ResetPasswordState.INIT;
			self._forgotPwdSubmitState = ObservableState.INIT;
		},
		resetChangeActiveCountryError() {
			self._changeActiveCountryError = null;
			self._changeActiveCountryState = ObservableState.INIT;
		},
		resetExtraCountryRegistrationError() {
			self._extraCountryRegistrationError = null;
			self._extraCountryRegistrationState = ObservableState.INIT;
		},
		resetRequestSandboxUsernameError() {
			self._requestSandboxUsernameError = null;
			self._requestSandboxUsernameState = ObservableState.INIT;
		},
		resetGetUserProfileError() {
			self._getUserProfileError = null;
			self._getUserProfileState = ObservableState.INIT;
		},
		resetSubscribeNewsletterError() {
			self._subscribeNewsletterError = null;
			self._subscribeNewsletterState = ObservableState.INIT;
		},
		resetContactFormAnnouncement() {
			self._contactFormAnnouncement = null;
		},
	}));

export interface ApplicationStoreType extends Instance<typeof ApplicationStore> {}
