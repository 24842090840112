import { getEnv, Instance, types } from 'mobx-state-tree';
import { IClientApi } from 'services';
import {
	ApplicationStore,
	BaselineStore,
	CountryStore,
	defaultApplications,
	defaultContactPriorities,
	defaultContactReasons,
	defaultEndCustomers,
	defaultRelatedReleases,
	defaultSalutations,
	defaultSandboxes,
	defaultSupportInfo,
	defaultBaselineProtocolFormTestResults,
	defaultBaselineProtocolFormYesNo,
	DocumentStore,
} from 'stores';
import { NewsAndFaqStore } from 'stores/news-faq-store/news-faq.store';
import { StandardTestStore } from 'stores/standard-test-store/standard-test.store';
import { ObservableState, ResetPasswordState } from 'types/app.typings';

export const RootStore = types
	.model('RootStore', {
		_countryStore: types.optional(
			types.late(() => CountryStore),
			{
				countries: [],
				error: null,
				state: ObservableState.INIT,
			}
		),
		_applicationStore: types.optional(
			types.late(() => ApplicationStore),
			{
				_maintenanceMode: false,
				_failedLoginAttempts: 0,
				_contactFormAnnouncement: null,
				// errors
				_loginError: null,
				_resetPwdError: null,
				_registrationError: null,
				_confirmEmailError: null,
				_contactSupportError: null,
				_changeActiveCountryError: null,
				_extraCountryRegistrationError: null,
				_requestSandboxUsernameError: null,
				_getUserProfileError: null,
				_subscribeNewsletterError: null,
				_maintenanceModeError: null,
				// states
				_loginState: ObservableState.INIT,
				_forgotPwdSubmitState: ObservableState.INIT,
				_resetPwdState: ObservableState.INIT,
				_resetPwdUIState: ResetPasswordState.INIT,
				_registrationState: ObservableState.INIT,
				_registrationUIState: ObservableState.INIT,
				_confirmEmailState: ObservableState.INIT,
				_contactSupportState: ObservableState.INIT,
				_changeActiveCountryState: ObservableState.INIT,
				_extraCountryRegistrationState: ObservableState.INIT,
				_requestSandboxUsernameState: ObservableState.INIT,
				_getUserProfileState: ObservableState.INIT,
				_subscribeNewsletterState: ObservableState.INIT,
				_maintenanceModeState: ObservableState.INIT,
				// select options
				endCustomerOptions: defaultEndCustomers,
				salutationOptions: defaultSalutations,
				sandboxOptions: defaultSandboxes,
				contactPriorityOptions: defaultContactPriorities,
				applicationOptions: defaultApplications,
				contactReasonOptions: defaultContactReasons,
				supportInfoOptions: defaultSupportInfo,
				baselineProtocolFormTestResultOptions: defaultBaselineProtocolFormTestResults,
				baselineProtocolFormYesNoOptions: defaultBaselineProtocolFormYesNo,
			}
		),
		_documentStore: types.optional(
			types.late(() => DocumentStore),
			{
				docs: [],
				relatedReleaseOptions: defaultRelatedReleases,
				error: null,
				state: ObservableState.INIT,
				opState: ObservableState.INIT,
			}
		),
		_baselineStore: types.optional(
			types.late(() => BaselineStore),
			{
				docs: [],
				error: null,
				state: ObservableState.INIT,
				lastUpdated: 0,
				_uploadingItems: [],
				_finishedItems: [],
				_failedItems: [],
				_uploadProtocolState: ObservableState.INIT,
				_uploadProtocolError: null,
				_submitProtocolState: ObservableState.INIT,
				_submitProtocolError: null,
				_opState: ObservableState.INIT,
				_testResult: null,
				_baselineDocumentation: null,
				_testPackage: null,
				_singleBaselineStatus: null,
			}
		),
		_testResultStore: types.optional(
			types.late(() => StandardTestStore),
			{
				docs: [],
				error: null,
				state: ObservableState.INIT,
				opState: ObservableState.INIT,
				lastUpdated: 0,
				_testResult: null,
				_testPackage: null,
			}
		),
		_newsAndFaqStore: types.optional(
			types.late(() => NewsAndFaqStore),
			{
				_faqError: null,
				_newsError: null,
				_faqState: ObservableState.INIT,
				_newsState: ObservableState.INIT,
				_faqCategories: [],
				_faqs: {
					docs: [],
					totalDocs: 0,
					limit: 10,
					page: 0,
					totalPages: 1,
					offset: undefined,
					hasPrevPage: false,
					hasNextPage: false,
					prevPage: null,
					nextPage: null,
					pagingCounter: 1,
					meta: undefined,
				},
				_news: {
					docs: [],
					totalDocs: 0,
					limit: 10,
					page: 0,
					totalPages: 1,
					offset: undefined,
					hasPrevPage: false,
					hasNextPage: false,
					prevPage: null,
					nextPage: null,
					pagingCounter: 1,
					meta: undefined,
				},
			}
		),
	})
	.views(self => ({
		get api(): IClientApi {
			return getEnv(self).api;
		},
		get countryStore() {
			return self._countryStore;
		},
		get applicationStore() {
			return self._applicationStore;
		},
		get documentStore() {
			return self._documentStore;
		},
		get baselineStore() {
			return self._baselineStore;
		},
		get testResultStore() {
			return self._testResultStore;
		},
		get newsAndFaqStore() {
			return self._newsAndFaqStore;
		},
	}))
	.actions(self => ({
		afterCreate() {
			self._countryStore.fetchCountries();
			self._applicationStore.getMaintenanceMode();
			// self._applicationStore.initialize({
			// error: null,
			// state: ObservableState.INIT,
			// maintenanceMode: false,
			// endCustomerOptions: defaultEndCustomers,
			// salutationOptions: defaultSalutations,
			// sandboxOptions: defaultSandboxes,
			// contactPriorityOptions: defaultContactPriorities,
			// applicationOptions: defaultApplications,
			// contactReasonOptions: defaultContactReasons,
			// supportInfoOptions: defaultSupportInfo,
			// });
		},
	}));

export type RootStoreType = Instance<typeof RootStore>;
