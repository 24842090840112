import { UseQueryOptions } from '@tanstack/react-query';
import { HealthCheckResult } from '@nestjs/terminus';
import { useFetch } from 'hooks';
import { apiRoutes } from './metaApiRoutes';

export const useGetMaintenanceDueDate = (queryConfig?: Omit<UseQueryOptions<string, Error, string, any>, 'queryKey'>) =>
	useFetch<string>(apiRoutes.getMaintenanceDueDate(), {}, queryConfig);

export const useGetReadiness = (queryConfig?: Omit<UseQueryOptions<number, Error, number, any>, 'queryKey'>) =>
	useFetch<number>(apiRoutes.getReadiness(), {}, queryConfig);

export const useGetHealth = (
	queryConfig?: Omit<UseQueryOptions<HealthCheckResult, Error, HealthCheckResult, any>, 'queryKey'>
) => useFetch<HealthCheckResult>(apiRoutes.getReadiness(), {}, queryConfig);
