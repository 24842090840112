/* eslint-disable no-console */
import Pusher from 'pusher-js';
import { PusherChannel } from 'types/app.typings';

export class EventSubscriber {
	private readonly pusherClient;
	constructor() {
		this.pusherClient = new Pusher(import.meta.env.VITE_PUSHER_KEY || '', {
			cluster: 'eu',
			forceTLS: true,
		});
		this.pusherClient.connection.bind('error', (err: Record<string, any>) => {
			if (err.error?.data?.code === 4004) {
				console.warn('Over limit!');
				this.pusherClient.disconnect();
			}
		});
		this.pusherClient.connection.bind('connected', console.log);
		this.pusherClient.connection.bind('disconnected', console.log);
	}

	disconnect() {
		this.pusherClient.disconnect();
	}

	subscribe(channel: PusherChannel) {
		if (this.pusherClient) {
			const _channel = this.pusherClient.subscribe(channel);
			_channel.bind('pusher:subscription_succeeded', () => console.log(`Channel ${channel} subscription OK`));
			_channel.bind('pusher:subscription_error', (status: string) =>
				console.log(`Channel ${channel} subscription failed with status ${status}`)
			);
		} else {
			console.error('Pusher client is null or not initialized');
		}
	}

	unsubscribe(channel: PusherChannel) {
		if (this.pusherClient) {
			this.pusherClient.unsubscribe(channel);
		} else {
			console.error('Pusher client is null or not initialized');
		}
	}

	bind(channelName: string, event: string, handler: (data: any) => void) {
		const channel = this.pusherClient.channel(channelName);
		if (channel) {
			channel.bind(event, handler);
		} else {
			console.error('Channel not exist');
		}
	}

	unbind(channelName: string, event: string) {
		const channel = this.pusherClient.channel(channelName);
		if (channel) {
			channel.unbind(event);
		} else {
			console.error('Channel not exist');
		}
	}
}
