import { CustomForbiddenReason } from 'types/app.typings';

export class LoginError extends Error {
	reason: CustomForbiddenReason | string;

	constructor(reason: CustomForbiddenReason | string, message: string) {
		super(message);
		this.reason = reason;
	}

	static isUserLoginError(error: Error) {
		return error instanceof LoginError;
	}

	static isUserCredentialsInvalid(error: LoginError) {
		return error.reason === CustomForbiddenReason.LoginFailure;
	}

	static isEmailUnconfirmed(error: LoginError) {
		return error.reason === CustomForbiddenReason.PendingConfirmation;
	}

	static isPendingFetch(error: LoginError) {
		return error.reason === CustomForbiddenReason.PendingFetch;
	}

	static isAccountUnapproved(error: LoginError) {
		return (
			error.reason === CustomForbiddenReason.PendingFetch || error.reason === CustomForbiddenReason.PendingApproval
		);
	}

	static isAccountRejected(error: LoginError) {
		return (
			error.reason === CustomForbiddenReason.InactiveSws ||
			error.reason === CustomForbiddenReason.MultiAccounts ||
			error.reason === CustomForbiddenReason.InsufficientInfo ||
			error.reason === CustomForbiddenReason.GeneralRejection
		);
	}
}
