import i18next from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import en from './en/translation.json';
import { convertLanguageJsonToObject } from './translations';

const translationsJson = {
	en: {
		translation: en,
	},
};

const formatOpts: any = {
	day: 'numeric',
	month: 'numeric',
	year: 'numeric',
	// second: 'numeric',
};

export type TranslationResource = typeof en;
export type LanguageKey = keyof TranslationResource;

// Create the 'translations' object to provide full intellisense support for the static json files.
convertLanguageJsonToObject(en);

export const i18n = i18next
	.use(intervalPlural)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		resources: translationsJson,

		fallbackLng: 'en',
		debug: !import.meta.env.PROD && import.meta.env.MODE !== 'test',

		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
			format: (value, format, lng) => {
				console.log('lng', lng);
				if (format === 'intlDate') {
					return new Intl.DateTimeFormat('en-GB', formatOpts).format(value);
				}
				return value;
			},
		},
	});

export { i18next };
