/**
 *
 * Asynchronously loads the component for UserProfilePage
 *
 */

import { lazyLoad } from 'utils/loadable';

const UserProfilePage = lazyLoad(
	() => import('./index'),
	module => module.UserProfilePage
);

export default UserProfilePage;
