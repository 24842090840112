/**
 *
 * Router
 *
 */
// @ts-nocheck
import { createRouter } from 'router5';
import browserPlugin from 'router5-plugin-browser';
// import persistentParamsPlugin from 'router5-plugin-persistent-params';
import loggerPlugin from 'router5-plugin-logger';
import { addHashFromParamsPluginFactory, mobxRouterPluginFactory, scrollToPluginFactory } from './plugins';
import { observable, action, computed, reaction } from 'mobx';
import { State, Router as Router5, NavigationOptions } from 'router5';
import { constants } from 'router5';
import { configService } from 'config';
import { AuthStore } from 'auth';
import { RouteMap } from 'router';
import { RootStoreType } from 'stores';

export { constants };

export interface RouterDependencies {
	authStore: AuthStore;
}

export class Router {
	@observable current: State;

	@observable private router: Router5<RouterDependencies>;
	private authStore: AuthStore;
	private rootStore: RootStoreType;

	constructor(authStore: AuthStore, rootStore: RootStoreType) {
		this.authStore = authStore;
		this.rootStore = rootStore;
		this.init();
	}

	@computed
	get name() {
		return this.current && this.current.name;
	}

	@computed
	get currentState() {
		return this.current;
	}

	@computed
	get params() {
		return this.current && this.current.params;
	}

	@computed
	get router5(): Router5<any> {
		return this.router;
	}

	init() {
		const developmentEnvironment = configService.isDev;
		// Router setup
		const router5 = createRouter<RouterDependencies>(this.routes, {
			queryParamsMode: 'loose',
			// trailing slashes to be optional
			strictTrailingSlash: true,
			// allows unknown URLs to create new state objects, routes name will be constants.UNKNOWN_ROUTE
			allowNotFound: true,
		});
		//in case of developmentEnvironment the scrollToPluginFactory is used twice. It does not affect he functionality
		router5.usePlugin(
			addHashFromParamsPluginFactory(),
			browserPlugin({
				useHash: false,
				// preserve hash needs to be disabled, otherwise remove addHashFromParamsPluginFactory
				preserveHash: false,
			}),
			mobxRouterPluginFactory(this),
			scrollToPluginFactory()
		);
		if (developmentEnvironment) {
			router5.usePlugin(loggerPlugin);
		}
		router5.subscribe(({ route, previousRoute }) => {
			// eslint-disable-next-line no-console
			console.log('Route', route);
		});
		router5.setDependency('authStore', this.authStore);
		this.setRouter(router5);
		router5.start();
		this.registerReactions();
		return router5;
	}

	@action
	setRouter(router: Router5<RouterDependencies>) {
		this.router = router;
	}

	@action
	setCurrent(state: State) {
		this.current = state;
	}

	buildPath(route: string, params: object = {}): string {
		return this.router.buildPath(route, params);
	}

	isActive(name: string, params: object = {}, strictEquality?: boolean, ignoreQueryParams?: boolean): boolean {
		return this.router.isActive(name, params, strictEquality, ignoreQueryParams);
	}

	navigate(name: string, params: object = {}, opts?: NavigationOptions) {
		this.router.navigate(name, params, opts || {});
	}

	navigateToDefault(opts?: NavigationOptions) {
		this.router.navigateToDefault(opts || {});
	}

	private registerReactions() {
		reaction(
			() => this.authStore.isAuthenticated,
			signedIn => {
				if (signedIn) {
					// redirect the user back to their original location
					// this.params can be an empty obj so destination is undefined -> must check!
					if (this.params && this.params.destination && this.params.destination !== null) {
						const { destination, ...params } = this.params;
						this.navigate(destination, params);
					} else {
						// or just redirect the user to the home page
						this.navigate(RouteMap.Home);
					}
				} else {
					this.navigate(RouteMap.Login);
				}
			}
		);
	}

	private checkAuthenticated(toState: State, authStore: AuthStore) {
		if (authStore.isAuthenticated) {
			return true;
		} else {
			// redirect to sign-in page if the user isn't signed in
			return Promise.reject({
				redirect: {
					name: RouteMap.Login,
					params: { ...toState.params, destination: toState.name },
				},
			});
		}
	}

	private checkUnAuthenticated(authStore: AuthStore) {
		if (authStore.isAuthenticated) {
			return Promise.reject({ redirect: { name: RouteMap.Home } });
		}
		this.ensureDataReset();
		return true;
	}

	private ensureDataReset() {
		// TODO: reset stores
	}

	@computed
	private get routes() {
		return [
			// General routes -> we don't care about the authentication state (no need to be signed in) -> for email routes
			{
				name: RouteMap.ToCPage,
				path: '/tos',
			},
			{
				name: RouteMap.DataProtectionGDPR,
				path: '/gdpr',
			},
			{
				name: RouteMap.ImprintPage,
				path: '/imprint',
			},
			{
				name: RouteMap.ConfirmRegistrationPage,
				path: '/portal/confirm-registration?:e&:t',
			},
			{
				name: RouteMap.ResetPasswordPage,
				path: '/portal/password/reset?:t',
			},
			// Unauthenticated-only routes
			{
				name: RouteMap.RegistrationPage,
				path: '/register',
				canActivate: (router, deps) => () => this.checkUnAuthenticated(deps.authStore),
			},
			{
				name: RouteMap.Login,
				path: '/',
				canActivate: (router, deps) => () => this.checkUnAuthenticated(deps.authStore),
			},
			// Authenticated routes
			{
				name: RouteMap.Home,
				path: '/portal',
				canActivate: (router, deps) => (toState: State, fromState: State, done) =>
					this.checkAuthenticated(toState, deps.authStore),
			},
			{
				name: RouteMap.UserProfilePage,
				path: '/portal/profile',
				canActivate: (router, deps) => (toState: State, fromState: State, done) =>
					this.checkAuthenticated(toState, deps.authStore),
			},
			{
				name: RouteMap.DocumentationPage,
				path: '/portal/documentation',
				canActivate: (router, deps) => (toState: State, fromState: State, done) =>
					this.checkAuthenticated(toState, deps.authStore),
			},
			{
				name: RouteMap.NamsDocumentationPage,
				path: '/portal/nams_documentation',
				canActivate: (router, deps) => (toState: State, fromState: State, done) =>
					this.checkAuthenticated(toState, deps.authStore),
			},
			{
				name: RouteMap.TestingPage,
				path: '/portal/testing',
				canActivate: (router, deps) => (toState: State, fromState: State, done) =>
					this.checkAuthenticated(toState, deps.authStore),
			},
			{
				name: RouteMap.BaselineTestingPage,
				path: '/portal/baseline-testing',
				canActivate: (router, deps) => (toState: State, fromState: State, done) =>
					this.checkAuthenticated(toState, deps.authStore),
			},
			{
				name: RouteMap.BaselineProtocolPage,
				path: '/portal/baseline-protocol?:test_id',
				canActivate: (router, deps) => (toState: State, fromState: State, done) =>
					this.checkAuthenticated(toState, deps.authStore),
			},
			{
				name: RouteMap.FaqPage,
				path: '/portal/faq',
				canActivate: (router, deps) => (toState: State, fromState: State, done) =>
					this.checkAuthenticated(toState, deps.authStore),
			},
			{
				name: RouteMap.TutorialPage,
				path: '/portal/tutorial',
				canActivate: (router, deps) => (toState: State, fromState: State, done) =>
					this.checkAuthenticated(toState, deps.authStore),
			},
		];
	}
}
