import { FC, useState } from 'react';
import { RootStoreContext } from './root-store.context';
import { RootStoreType } from './root.store';

type Props = {
	rootStore: RootStoreType;
	children: any;
};

export const RootStoreProvider: FC<Props> = ({ children, rootStore }) => {
	const [rootStoreInstance] = useState(() => rootStore);

	return <RootStoreContext.Provider value={rootStoreInstance}>{children}</RootStoreContext.Provider>;
};
