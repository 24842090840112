/* eslint-disable no-console */
import { toJS } from 'mobx';
import { flow, getParent, Instance, types } from 'mobx-state-tree';
import { ObservableState } from 'types/app.typings';
import { RootStore } from 'stores';
import { NmvsCountryDto } from 'utils/dtos/nmvs-country.dto';
import isoCountries from '../../assets/countries/countries-iso-3166-1-alpha-2.json';

export const CountryItem = types.model('CountryItem', {
	id: types.identifier,
	code: types.string,
	name: types.string,
	createdAt: types.string,
	updatedAt: types.string,
});

export const CountryStore = types
	.model('CountryStore', {
		error: types.maybeNull(types.frozen()),
		state: types.enumeration(Object.values(ObservableState)),
		countries: types.array(CountryItem),
	})
	.views(self => ({
		get countryCount() {
			return self.countries.length;
		},
		get isLoading() {
			return self.state === ObservableState.PENDING;
		},
		get hasError() {
			return self.state === ObservableState.ERROR;
		},
		get countryList() {
			const res = toJS(self.countries);
			return res.length > 0
				? res.sort(function (a: NmvsCountryDto, b: NmvsCountryDto) {
						if (a.name > b.name) return 1;
						if (a.name < b.name) return -1;
						return 0;
					})
				: [];
		},
		get nmvsCountryOpts() {
			const res = toJS(self.countries);
			return res.length > 0
				? res
						.sort(function (a: NmvsCountryDto, b: NmvsCountryDto) {
							if (a.name > b.name) return 1;
							if (a.name < b.name) return -1;
							return 0;
						})
						.map(country => ({
							value: country.code,
							label: country.name,
						}))
				: [];
		},
		get countryListWithNoneOption() {
			const res = toJS(self.countries);
			res.sort(function (a: NmvsCountryDto, b: NmvsCountryDto) {
				if (a.name > b.name) return 1;
				if (a.name < b.name) return -1;
				return 0;
			});
			return [{ id: '', name: 'None', code: '' }, ...res];
		},
		get isoCountryList() {
			return isoCountries.map((item: { Code: string; Name: string }) => ({
				value: item.Code,
				label: item.Name,
			}));
		},
		getCodesByIds(ids: string[]): string[] {
			return toJS(self.countries)
				.filter(item => ids.includes(item.id))
				.map(item => item.code);
		},
		get rootStore(): any {
			return getParent<typeof RootStore>(self);
		},
		get promiseState() {
			return self.state;
		},
	}))
	.actions(self => ({
		fetchCountries: flow(function* () {
			self.state = ObservableState.PENDING;
			try {
				const res = yield self.rootStore.api.getNmvsCountries();
				// remove Finland from the list
				self.countries = res.filter((item: NmvsCountryDto) => item.code !== 'FI');
				self.state = ObservableState.DONE;
			} catch (e: any) {
				console.error('Unable to fetch NMVS countries', e);
				self.error = e;
				self.state = ObservableState.ERROR;
			}
		}),
	}));

export type CountryStoreType = Instance<typeof CountryStore>;
