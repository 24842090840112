//@ts-nocheck
import { PluginFactory, Router as Router5 } from 'router5';

export function addHashFromParamsPluginFactory() {
	const addHashFromParamsPlugin: PluginFactory = (router5: Router5) => {
		const originalBuildUrl = router5.buildPath;

		router5.buildPath = function (route: any, params?: any) {
			if (params != null && params.hash) {
				const hash = params.hash;
				const paramsNew = {
					...params,
				};
				delete paramsNew.hash;

				const url = originalBuildUrl.call(router5, route, paramsNew);
				return url + `#${hash}`;
			}
			return originalBuildUrl.call(router5, route, params);
		};

		return {};
	};

	return addHashFromParamsPlugin;
}
