/**
 *
 * Asynchronously loads the component for LoginPage
 *
 */

import { lazyLoad } from 'utils/loadable';

const LoginPage = lazyLoad(
	() => import('./index'),
	module => module.LoginPage
);

export default LoginPage;
