/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as Sentry from '@sentry/react';
import { useRootStore } from 'hooks';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { useRoute } from 'react-router5';
import { RouteMap } from 'router';
import { ApplicationStoreType } from 'stores';

import BaselinePage from 'app/pages/BaselinePage/Loadable';
import BaselineProtocolPage from 'app/pages/BaselineProtocolPage/Loadable';
import ConfirmRegistrationPage from 'app/pages/ConfirmRegistrationPage/Loadable';
import DocumentationPage from 'app/pages/DocumentationPage/Loadable';
import FaqPage from 'app/pages/FaqPage/Loadable';
import ForgotPasswordPage from 'app/pages/ForgotPasswordPage/Loadable';
import GdprPage from 'app/pages/GdprPage/Loadable';
import HomePage from 'app/pages/HomePage/Loadable';
import ImprintPage from 'app/pages/ImprintPage/Loadable';
import LandingPage from 'app/pages/LandingPage/Loadable';
import LoginPage from 'app/pages/LoginPage/Loadable';
import NamsDocumentationPage from 'app/pages/NamsDocumentationPage/Loadable';
import NotFoundPage from 'app/pages/NotFoundPage/Loadable';
import ResetPasswordPage from 'app/pages/ResetPasswordPage/Loadable';
import TermsAndConditionsPage from 'app/pages/TermsAndConditionsPage/Loadable';
import TestAndFixPage from 'app/pages/TestAndFixPage/Loadable';
import TutorialPage from 'app/pages/TutorialPage/Loadable';
import UserProfilePage from 'app/pages/UserProfilePage/Loadable';
import { configService } from 'config';
import { useGetReadiness } from 'meta-services/metaApiServices';
import DevInfo from './components/base/DevInfo/Loadable';
import { MaintenancePage } from './pages/MaintenancePage/Loadable';

// @ts-ignore TODO: wait for better React 18 typings
// const globalStyles = <GlobalStyles />;

type Props = {
	children?: any;
};

const App: FC<Props> = observer(() => {
	const applicationStore: ApplicationStoreType = useRootStore().applicationStore;
	const { route } = useRoute();

	const { isLoading, error, data } = useGetReadiness({
		enabled: true,
		refetchInterval: 900000, // 15 min interval
		refetchIntervalInBackground: true,
		refetchOnWindowFocus: true,
	});

	console.log('Readiness isLoading, error, data:', isLoading, error, data);

	const renderRoute = () => {
		let component: JSX.Element | null = null;
		if (applicationStore.isMaintenance) {
			component = <MaintenancePage key="maintenance-page" />;
			return component;
		}
		switch (true) {
			// Portal routes
			case route.name === RouteMap.Home:
				component = <HomePage key="home-page" />;
				break;
			case route.name === RouteMap.DocumentationPage:
				component = <DocumentationPage key="documentation-page" />;
				break;
			case route.name === RouteMap.NamsDocumentationPage:
				component = <NamsDocumentationPage key="nams-documentation-page" />;
				break;
			case route.name === RouteMap.TestingPage:
				component = <TestAndFixPage key="test-n-fix-page" />;
				break;
			case route.name === RouteMap.BaselineTestingPage:
				component = <BaselinePage key="baseline-testing-page" />;
				break;
			case route.name === RouteMap.BaselineProtocolPage && route.params['test_id'] && route.params['test_id'] !== null:
				component = (
					<BaselineProtocolPage
						key={`baseline-protocol-page-${route.params['test_id']}`}
						testId={route.params['test_id']}
					/>
				);
				break;
			case route.name === RouteMap.FaqPage:
				component = <FaqPage key="faq-page" />;
				break;
			case route.name === RouteMap.Login:
				component = <LoginPage key="login-page" />;
				break;
			case route.name === RouteMap.UserProfilePage:
				component = <UserProfilePage key="user-profile-page" />;
				break;
			// Registration RouteMap
			case route.name === RouteMap.RegistrationPage:
				component = <LandingPage key="registration-landing-page" />;
				break;
			case route.name === RouteMap.ToCPage:
				component = <TermsAndConditionsPage key="toc-page" />;
				break;
			case route.name === RouteMap.DataProtectionGDPR:
				component = <GdprPage key="gdpr-page" />;
				break;
			case route.name === RouteMap.ImprintPage:
				component = <ImprintPage key="imprint-page" />;
				break;
			case route.name === RouteMap.TutorialPage:
				component = <TutorialPage key="tutorial-page" />;
				break;
			case route.name === RouteMap.ResetPasswordPage:
				if (route.params.t && route.params.t !== null) {
					component = <ResetPasswordPage key={`reset-password-page-${route.params.t}`} token={route.params.t} />;
				} else {
					component = <ForgotPasswordPage key="forgot-password-page" />;
				}
				break;
			case route.name === RouteMap.ConfirmRegistrationPage &&
				route.params.e &&
				route.params.t &&
				route.params.e !== null &&
				route.params.t !== null:
				component = (
					<ConfirmRegistrationPage
						key={`confirm-registration-page-${route.params.e}-${route.params.t}`}
						email={route.params.e}
						token={route.params.t}
					/>
				);
				break;
			default:
				component = <NotFoundPage />;
		}
		return component;
	};

	if (!route) {
		return null;
	}

	return (
		<div id="sws-portal-app">
			{configService.isDev && <DevInfo />}
			{renderRoute()}
		</div>
	);
});

export default Sentry.withProfiler(App);
