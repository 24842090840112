import {
	ArrayNotEmpty,
	IsArray,
	IsBoolean,
	IsMongoId,
	IsNotEmpty,
	IsNumber,
	IsOptional,
	IsString,
} from 'class-validator';
import { NewsAndFaq } from 'types/app.typings';

export class SwsNewsDto implements NewsAndFaq {
	@IsNotEmpty()
	@IsMongoId()
	id!: string;

	@IsString()
	guid!: string;

	@IsNotEmpty()
	@IsString()
	title!: string;

	@IsNotEmpty()
	@IsString()
	content!: string;

	@IsNotEmpty()
	@IsArray()
	@ArrayNotEmpty()
	countries!: Array<{ code: string; name: string }>;

	@IsNotEmpty()
	@IsBoolean()
	isPublished!: boolean;

	@IsNumber()
	sortingPriority!: number;

	@IsNotEmpty()
	publishedBy!: Record<string, any>;

	@IsNotEmpty()
	publishedAt!: string;

	@IsNotEmpty()
	createdAt!: string;

	@IsNotEmpty()
	updatedAt!: string;

	@IsNotEmpty()
	lastEditedByAlt!: Record<string, any>;

	@IsNotEmpty()
	publishedByAlt!: Record<string, any>;
}

export class ApiResponseSwsNewsDto {
	@IsNotEmpty()
	@IsArray()
	docs!: SwsNewsDto[];

	@IsNotEmpty()
	@IsNumber()
	totalDocs!: number;

	@IsNotEmpty()
	@IsNumber()
	limit!: number;

	@IsNotEmpty()
	@IsNumber()
	page!: number;

	@IsNotEmpty()
	@IsNumber()
	totalPages!: number;

	@IsOptional()
	@IsNumber()
	offset?: number;

	@IsNotEmpty()
	@IsBoolean()
	hasPrevPage!: boolean;

	@IsNotEmpty()
	@IsBoolean()
	hasNextPage!: boolean;

	@IsOptional()
	@IsNumber()
	prevPage?: number;

	@IsOptional()
	@IsNumber()
	nextPage?: number;

	@IsNotEmpty()
	@IsNumber()
	pagingCounter!: number;

	@IsOptional()
	meta?: Record<string, any>;
}

export type NewsQuery = {
	page: number;
	size: number;
};
