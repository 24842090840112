import { AuthStore } from 'auth';
import { FC, useState } from 'react';
import { AuthStoreContext } from './auth-store.context';

type Props = {
	authStore: AuthStore;
	children: any;
};

export const AuthStoreProvider: FC<Props> = ({ children, authStore }) => {
	const [authStoreInstance] = useState(() => authStore);

	return <AuthStoreContext.Provider value={authStoreInstance}>{children}</AuthStoreContext.Provider>;
};
