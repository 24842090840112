/**
 *
 * Asynchronously loads the component for DevInfo
 *
 */

import { lazyLoad } from 'utils/loadable';

const DevInfo = lazyLoad(
	() => import('./index'),
	module => module.DevInfo
);

export default DevInfo;
