/**
 *
 * Toaster
 *
 */
import { OverlayToasterProps, ToastProps, OverlayToaster } from '@blueprintjs/core';
import { FC, memo, useRef } from 'react';
import { ToasterProvider } from './ToasterProvider';

type Props = OverlayToasterProps & {
	children?: any;
};

export const Toaster: FC<Props> = memo(({ children, ...restProps }) => {
	const toasterRef = useRef<OverlayToaster | null>(null);

	const addToast = (toast: ToastProps) => {
		if (toasterRef.current) toasterRef.current.show(toast);
	};

	return (
		<ToasterProvider value={{ addToast }}>
			{children}
			<OverlayToaster {...restProps} ref={toasterRef} />
		</ToasterProvider>
	);
});
