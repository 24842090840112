/**
 *
 * Asynchronously loads the component for GdprPage
 *
 */

import { lazyLoad } from 'utils/loadable';

const GdprPage = lazyLoad(
	() => import('./index'),
	module => module.GdprPage
);

export default GdprPage;
