/**
 *
 * Asynchronously loads the component for LandingPage
 *
 */

import { lazyLoad } from 'utils/loadable';

const LandingPage = lazyLoad(
	() => import('./index'),
	module => module.LandingPage
);

export default LandingPage;
