/* eslint-disable no-console */
import { toJS } from 'mobx';
import { flow, getParent, Instance, types } from 'mobx-state-tree';
import { RootStore } from 'stores';
import { FaqQuery } from 'utils/dtos/sws-faq.dto';
import { NewsQuery } from 'utils/dtos/sws-news.dto';
import { ObservableState } from 'types/app.typings';

export const FaqItem = types.model('FaqItem', {
	title: types.string,
	content: types.string,
	publishedAt: types.string,
	category: types.string,
	sortingPriority: types.number,
	updatedAt: types.string,
	isPublished: types.boolean,
});

export const NewsItem = types.model('NewsItem', {
	title: types.string,
	content: types.string,
	publishedAt: types.string,
	sortingPriority: types.number,
	updatedAt: types.string,
	isPublished: types.boolean,
});

const PaginatedApiResponse = types.model({
	totalDocs: types.optional(types.number, 0),
	limit: types.optional(types.number, 10),
	page: types.optional(types.number, 0),
	totalPages: types.optional(types.number, 1),
	offset: types.maybe(types.number),
	hasPrevPage: types.optional(types.boolean, false),
	hasNextPage: types.optional(types.boolean, false),
	prevPage: types.maybeNull(types.number),
	nextPage: types.maybeNull(types.number),
	pagingCounter: types.maybe(types.number),
	meta: types.maybe(types.frozen()),
});

const BaseApiResponseFaq = types.model({
	docs: types.array(FaqItem),
});

const BaseApiResponseNews = types.model({
	docs: types.array(NewsItem),
});

const ApiResponseFaq = types.compose(PaginatedApiResponse, BaseApiResponseFaq).named('ApiResponseFaq');
const ApiResponseNews = types.compose(PaginatedApiResponse, BaseApiResponseNews).named('ApiResponseNews');

export type NewsItemInstance = Instance<typeof NewsItem>;
export type FaqItemInstance = Instance<typeof FaqItem>;

export const NewsAndFaqStore = types
	.model('NewsAndFaqStore', {
		_faqError: types.maybeNull(types.frozen()),
		_newsError: types.maybeNull(types.frozen()),
		_faqState: types.enumeration(Object.values(ObservableState)),
		_newsState: types.enumeration(Object.values(ObservableState)),
		_faqs: types.frozen(ApiResponseFaq),
		_faqCategories: types.array(types.string),
		_news: types.frozen(ApiResponseNews),
	})
	.views(self => ({
		// Faq
		get faqList() {
			return toJS(self._faqs.docs) || [];
		},
		get faqCategories() {
			return toJS(self._faqCategories) || [];
		},
		get totalCountFaq(): number {
			return self._faqs.totalDocs || 0;
		},
		get totalPagesFaq(): number {
			return self._faqs.totalPages || 0;
		},
		get pageSizeFaq(): number {
			return self._faqs.limit || 0;
		},
		get pageFaq(): number {
			return self._faqs.page || 0;
		},
		get hasPrevPageFaq(): boolean {
			return self._faqs.hasPrevPage || false;
		},
		get hasNextPageFaq(): boolean {
			return self._faqs.hasNextPage || false;
		},
		get isFaqLoading() {
			return self._faqState === ObservableState.PENDING;
		},
		get hasFaqError() {
			return self._faqState === ObservableState.ERROR;
		},
		get promiseFaqState() {
			return self._faqState;
		},
		// News
		get newsList() {
			return toJS(self._news.docs) || [];
		},
		get totalCountNews(): number {
			return self._news.totalDocs || 0;
		},
		get totalPagesNews(): number {
			return self._news.totalPages || 0;
		},
		get pageSizeNews(): number {
			return self._news.limit || 0;
		},
		get pageNews(): number {
			return self._news.page || 0;
		},
		get hasPrevPageNews(): boolean {
			return self._news.hasPrevPage || false;
		},
		get hasNextPageNews(): boolean {
			return self._news.hasNextPage || false;
		},
		get isNewsLoading() {
			return self._newsState === ObservableState.PENDING;
		},
		get hasNewsError() {
			return self._newsState === ObservableState.ERROR;
		},
		get promiseNewsState() {
			return self._newsState;
		},
		// misc
		get rootStore(): any {
			return getParent<typeof RootStore>(self);
		},
	}))
	.actions(self => ({
		fetchNews: flow<void, [query: NewsQuery]>(function* (query: NewsQuery) {
			self._newsState = ObservableState.PENDING;
			try {
				self._news = yield self.rootStore.api.getNews(query);
				self._newsState = ObservableState.DONE;
			} catch (e: any) {
				console.error('Unable to fetch news', e);
				self._newsError = e;
				self._newsState = ObservableState.ERROR;
			}
		}),
		searchFaq: flow<void, [search: FaqQuery]>(function* (search: FaqQuery) {
			self._faqState = ObservableState.PENDING;
			try {
				self._faqs = yield self.rootStore.api.searchFaqs(search);
				self._faqState = ObservableState.DONE;
			} catch (e: any) {
				console.error('Unable to fetch FAQs', e);
				self._faqError = e;
				self._faqState = ObservableState.ERROR;
			}
		}),
		getFaqCategories: flow<void, []>(function* () {
			self._faqState = ObservableState.PENDING;
			try {
				self._faqCategories = yield self.rootStore.api.getFaqCategories();
				self._faqState = ObservableState.DONE;
			} catch (e: any) {
				console.error('Unable to fetch FAQ categories', e);
				self._faqError = e;
				self._faqState = ObservableState.ERROR;
			}
		}),
		resetNews() {
			self._newsError = null;
			self._newsState = ObservableState.INIT;
			self._news = {
				docs: [],
				totalDocs: 0,
				limit: 10,
				page: 0,
				totalPages: 1,
				offset: undefined,
				hasPrevPage: false,
				hasNextPage: false,
				prevPage: null,
				nextPage: null,
				pagingCounter: 1,
				meta: undefined,
			};
		},
		resetFaq() {
			self._faqError = null;
			self._faqState = ObservableState.INIT;
			self._faqs = {
				docs: [],
				totalDocs: 0,
				limit: 10,
				page: 0,
				totalPages: 1,
				offset: undefined,
				hasPrevPage: false,
				hasNextPage: false,
				prevPage: null,
				nextPage: null,
				pagingCounter: 1,
				meta: undefined,
			};
		},
		resetStore() {
			self._faqError = null;
			self._newsError = null;
			self._faqState = ObservableState.INIT;
			self._newsState = ObservableState.INIT;
			self._faqs = {
				docs: [],
				totalDocs: 0,
				limit: 10,
				page: 0,
				totalPages: 1,
				offset: undefined,
				hasPrevPage: false,
				hasNextPage: false,
				prevPage: null,
				nextPage: null,
				pagingCounter: 1,
				meta: undefined,
			};
			self._news = {
				docs: [],
				totalDocs: 0,
				limit: 10,
				page: 0,
				totalPages: 1,
				offset: undefined,
				hasPrevPage: false,
				hasNextPage: false,
				prevPage: null,
				nextPage: null,
				pagingCounter: 1,
				meta: undefined,
			};
		},
	}));

export interface NewsAndFaqStoreType extends Instance<typeof NewsAndFaqStore> {}
