import isEmpty from 'lodash/isEmpty';
import { action, computed, makeObservable, observable } from 'mobx';
import { storeGetItem, storeRemoveItem, storeSetItem } from 'utils/storage';
import { AuthenticatedUser, RegistrationStatus, UserSoftwareCountry } from 'types/app.typings';

const STORAGE_KEY = 'swsuser';

export class AuthStore {
	error: Error | null;
	authenticatedUser: AuthenticatedUser | null = null;

	constructor() {
		makeObservable(this, {
			authenticatedUser: observable.ref,
			error: observable,
			isAuthenticated: computed,
			accessToken: computed,
			downloadToken: computed,
			email: computed,
			sandboxUsernameAvailable: computed,
			acceptedCountries: computed,
			userSWCountries: computed,
			activeCountry: computed,
			hasError: computed,
			setUser: action,
			updateUser: action,
			setError: action,
			login: action,
			logout: action,
		});
		this.authenticatedUser = storeGetItem(STORAGE_KEY);
		this.error = null;
	}

	login(user: AuthenticatedUser) {
		this.setUser(user);
	}

	logout(): void {
		this.authenticatedUser = null;
		storeRemoveItem(STORAGE_KEY);
	}

	get hasError() {
		return !!this.error;
	}

	get isAuthenticated() {
		return !isEmpty(this.authenticatedUser);
	}

	get accessToken() {
		return this.authenticatedUser ? this.authenticatedUser.token : null;
	}

	get downloadToken() {
		return this.authenticatedUser ? this.authenticatedUser.downloadToken : null;
	}

	get email() {
		return this.authenticatedUser ? this.authenticatedUser.email : null;
	}

	get sandboxUsernameAvailable(): boolean {
		return this.authenticatedUser ? this.authenticatedUser.sandbox1UsernameAvailable : false;
	}

	get acceptedCountries(): UserSoftwareCountry[] | null {
		return this.authenticatedUser
			? this.authenticatedUser.softwareCountry.filter(country => country.status === RegistrationStatus.ACCEPTED)
			: null;
	}

	get userSWCountries(): UserSoftwareCountry[] | null {
		return this.authenticatedUser ? this.authenticatedUser.softwareCountry : null;
	}

	get activeCountry(): UserSoftwareCountry | null {
		return this.authenticatedUser ? this.authenticatedUser.selectedSoftwareCountry : null;
	}

	setUser(user: AuthenticatedUser) {
		this.authenticatedUser = user;
		storeSetItem(STORAGE_KEY, user);
	}

	updateUser(data: Record<string, any>) {
		this.authenticatedUser = Object.assign({}, this.authenticatedUser, data);
		storeSetItem(STORAGE_KEY, this.authenticatedUser);
	}

	setError(error: any) {
		this.error = error;
	}
}

export const authModule = new AuthStore();
