import { Colors, Icon } from '@blueprintjs/core';
import { MouseEvent } from 'react';
import { SelectOption } from 'types/app.typings';
import { dayjs } from './libs/dayjs';
import Tooltip from 'app/components/base/Tooltip/Loadable';

type FaaCFunction = <T>(args: T) => React.ReactNode;

export function getChildrenOrFunction<T>(children: React.ReactNode | FaaCFunction, args: T): React.ReactNode {
	if (typeof children === 'function') {
		return children(args);
	}
	return children;
}

export const getGreetingTime = () => {
	const now = dayjs();
	const currentHour = now.hour();
	return currentHour >= 4 && currentHour < 12 // after 4:00AM and before 12:00PM
		? 'Good morning'
		: currentHour >= 12 && currentHour <= 17 // after 12:00PM and before 6:00pm
			? 'Good afternoon'
			: currentHour > 17 || currentHour < 4 // after 5:59pm or before 4:00AM (to accommodate night owls)
				? 'Good evening' // if for some reason the calculation didn't work
				: 'Hello';
};

export const isModifiedEvent = (event: MouseEvent<HTMLElement>) =>
	!!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

export const isNavigableEvent = (event: MouseEvent<HTMLElement>) => {
	const anchorElement = event.currentTarget as HTMLAnchorElement;
	return (
		!event.defaultPrevented && // onClick prevented default
		event.button === 0 && // ignore right clicks
		!(anchorElement && anchorElement.target) && // let browser handle "target=_blank" etc.
		!isModifiedEvent(event)
	); // ignore clicks with modifier keys
};

export const blockEvent = (event: any) => {
	event.preventDefault();
	event.stopPropagation();
	if (event.target.tagName !== 'A' || !('href' in event.target)) {
		return;
	}
	if (event.target.target) {
		window.open(event.target.href, event.target.target);
	} else {
		window.location.href = event.target.href;
	}
};

export const generateRandomDecimalNumber = (min: number, max: number, decimalPlaces: number) => {
	const rand = Math.random() < 0.5 ? (1 - Math.random()) * (max - min) + min : Math.random() * (max - min) + min; // could be min or max or anything in between
	const power = Math.pow(10, decimalPlaces);
	return Math.floor(rand * power) / power;
};

export function renderPriorityIndicator(value?: number) {
	switch (value) {
		case 1:
			return '🟢';
		case 2:
			return '🟠';
		case 3:
			return '🔴';
		default:
			return '⚪️';
	}
}

export function getColorByPriority(priority?: number) {
	switch (priority) {
		case 1: // low
			return [Colors.TURQUOISE5, Colors.TURQUOISE3];
		case 2: // normal
			return [Colors.GOLD5, Colors.GOLD3];
		case 3: // high
			return [Colors.RED5, Colors.RED3];
		default:
			return [Colors.GRAY5, Colors.GRAY3];
	}
}

export function renderPriorityLabel(value?: number) {
	switch (value) {
		case 1:
			return (
				<Tooltip content="Low">
					<Icon icon="snowflake" size={30} color={Colors.TURQUOISE3} />
				</Tooltip>
			);
		case 2:
			return (
				<Tooltip content="Medium">
					<Icon icon="tag" size={30} color={Colors.GOLD3} />
				</Tooltip>
			);
		case 3:
			return (
				<Tooltip content="High">
					<Icon icon="flame" size={30} color={Colors.RED3} />
				</Tooltip>
			);
		default:
			return 'Unknown';
	}
}

export function arrayContainsOption(optionArr: SelectOption[], optionToFind: SelectOption): boolean {
	return optionArr.some((option: SelectOption) => option.value === optionToFind.value);
}

export function arrayContainsStringValue(arr: string[], valueToFind: string): boolean {
	return arr.some((elem: string) => elem === valueToFind);
}

export function getIndexFromStringArray(stringArr: string[], stringToFind: string): number {
	return stringArr.indexOf(stringToFind);
}

export function getIndexFromSelectOptionArray(arr: SelectOption[], itemToFind: SelectOption): number {
	return arr.findIndex(item => item.value.toLowerCase() === itemToFind.value.toLowerCase());
}

export const areItemsEqual = (item1: SelectOption, item2: SelectOption) => {
	// Compare only the values (ignoring case) just for simplicity.
	return item1.value.toLowerCase() === item2.value.toLowerCase();
};

export function buildSelectedOptionsFromStringArr(
	selectedOptions: string[],
	originalOptions: SelectOption[]
): SelectOption[] {
	const res = selectedOptions
		.map(item => {
			const fullCountry = originalOptions.find(elem => elem.value === item);
			if (fullCountry) {
				return {
					label: fullCountry.label,
					value: fullCountry.value,
					disabled: false,
					isSelected: true,
				};
			}
			return null;
		})
		.filter(item => item !== null);
	return res as SelectOption[];
}

function escapeRegExpChars(text: string) {
	return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
}

export function highlightText(text: string, query: string) {
	let lastIndex = 0;
	const words = query
		.split(/\s+/)
		.filter(word => word.length > 0)
		.map(escapeRegExpChars);
	if (words.length === 0) {
		return [text];
	}
	const regexp = new RegExp(words.join('|'), 'gi');
	const tokens: React.ReactNode[] = [];
	while (true) {
		const match = regexp.exec(text);
		if (!match) {
			break;
		}
		const length = match[0].length;
		const before = text.slice(lastIndex, regexp.lastIndex - length);
		if (before.length > 0) {
			tokens.push(before);
		}
		lastIndex = regexp.lastIndex;
		tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
	}
	const rest = text.slice(lastIndex);
	if (rest.length > 0) {
		tokens.push(rest);
	}
	return tokens;
}

export function compareSelectOption(a: SelectOption, b: SelectOption) {
	if (a.value > b.value) return -1;
	if (a.value < b.value) return 1;
	return 0;
}

export function buildAppUrl(path: string) {
	const appUrl = import.meta.env.VITE_API_ENDPOINT ? import.meta.env.VITE_API_ENDPOINT : '/api';
	return appUrl + path;
}

export function extractMetadataFromDownloadUri(inputString: string | null | undefined): {
	metadata: Record<string, any>;
	url: string;
} {
	if (!inputString) {
		return { metadata: {}, url: '' };
	}
	// example inputString: "{\"isplaceholder\":\"true\"}https://stnmvsswsportaldev.blob.core.windows.net/baselinetestprotocols/...."
	// Separate the JSON object from the URL
	const boundaryIndex = inputString.indexOf('https://');
	const jsonString = inputString.substring(0, boundaryIndex);
	const url = inputString.substring(boundaryIndex);

	// Parse the JSON object and return it as metadata
	const metadata = JSON.parse(jsonString);

	return { metadata, url };
}

export function extractTagsFromDownloadUri(inputString?: string | null): {
	tags: Record<string, any>;
	url: string;
} {
	if (!inputString) {
		return { tags: {}, url: '' };
	}
	// example inputString: "{\"isplaceholder\":\"true\"}https://stnmvsswsportaldev.blob.core.windows.net/baselinetestprotocols/...."
	// Separate the JSON object from the URL
	const boundaryIndex = inputString.indexOf('https://');
	const jsonString = inputString.substring(0, boundaryIndex);
	const url = inputString.substring(boundaryIndex);

	// Parse the JSON object and return it as metadata only if jsonString is not empty
	if (!jsonString) {
		return { tags: {}, url };
	}
	const tags = JSON.parse(jsonString);
	return { tags, url };
}
