import { FC, useState } from 'react';
import { PusherContext } from './pusher.context';
import { EventSubscriber } from './event.subscriber';

type Props = {
	children?: any;
};

export const PusherProvider: FC<Props> = ({ children }) => {
	const [pusher] = useState(() => new EventSubscriber());

	return <PusherContext.Provider value={pusher}>{children}</PusherContext.Provider>;
};
