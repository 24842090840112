import { IsMongoId, IsNotEmpty } from 'class-validator';

export class NmvsCountryDto {
	@IsNotEmpty()
	@IsMongoId()
	id!: string;

	@IsNotEmpty()
	code!: string;

	@IsNotEmpty()
	name!: string;

	@IsNotEmpty()
	createdAt!: string;

	@IsNotEmpty()
	updatedAt!: string;
}
