import axios, { AxiosInstance } from 'axios';

const getAxiosInstance = (token?: string | null): AxiosInstance => {
	console.log('token', token);
	const instance = axios.create();
	// instance.defaults.baseURL = '';
	instance.defaults.timeout = 300000; // Socket timeout to 5 minutes
	// instance.defaults.headers.common.Authorization = `Bearer ${token}`;
	// instance.interceptors.response.use(
	//         // catch 401 error to log out
	//         v => v,
	//         (error: AxiosError) => {
	//           if (error.response && error.response.status === 401) {
	//             console.log('Unauthorized...');
	//           }
	//           return Promise.reject(error);
	//         }
	// );
	return instance;
};

export const httpService = {
	get: <T>(url: string, params?: object) =>
		getAxiosInstance().get<T>(url, {
			// headers: {
			//   token: Cookies.get('token'),
			// },
			...params,
		}),
	post: <T>(url: string, data: any) =>
		getAxiosInstance().post<T>(url, data, {
			// headers: {
			//   token: Cookies.get('token'),
			// },
		}),
	patch: <T>(url: string, data: any) =>
		getAxiosInstance().patch<T>(url, data, {
			// headers: {
			//   token: Cookies.get('token'),
			// },
		}),
	delete: <T>(url: string) =>
		getAxiosInstance().delete<T>(url, {
			// headers: {
			//   token: Cookies.get('token'),
			// },
		}),
};
