/**
 *
 * ErrorFallback (to use with Sentry ErrorBoundary)
 *
 */
import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import styles from './ErrorFallback.module.scss';

export const ErrorFallback = () => {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	}, []);

	return (
		<div className={clsx(styles.errorFallback, { [styles.loading]: loading })}>
			<h1 className={styles.header}>500</h1>
			<h2 className={styles.subheader}>
				Unexpected Error <b>:(</b>
			</h2>
			<div className={styles.gears}>
				<div className={clsx(styles.gear, styles.one)}>
					<div className={styles.bar}></div>
					<div className={styles.bar}></div>
					<div className={styles.bar}></div>
				</div>
				<div className={clsx(styles.gear, styles.two)}>
					<div className={styles.bar}></div>
					<div className={styles.bar}></div>
					<div className={styles.bar}></div>
				</div>
				<div className={clsx(styles.gear, styles.three)}>
					<div className={styles.bar}></div>
					<div className={styles.bar}></div>
					<div className={styles.bar}></div>
				</div>
			</div>
		</div>
	);
};
