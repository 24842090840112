import store from 'store';

export function storeGetItem(key: string) {
	try {
		// const obj = localStorage.getItem(key);
		// return obj ? JSON.parse(obj) : null;
		const obj = store.get(key);
		return obj ? obj : null;
	} catch (e: any) {
		// console.error(e);
		return null;
	}
}

export function storeSetItem(key: string, item: any) {
	try {
		// localStorage.setItem(key, JSON.stringify(item));
		store.set(key, item);
	} catch (e: any) {
		return;
	}
}

export function storeRemoveItem(key: string) {
	try {
		// localStorage.removeItem(key);
		store.remove(key);
	} catch (e: any) {
		return;
	}
}
