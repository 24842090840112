import { RegistrationFailureReason } from 'types/app.typings';

export class RegistrationError extends Error {
	reason: RegistrationFailureReason | string;

	constructor(reason: RegistrationFailureReason | string, message: string) {
		super(message);
		this.reason = reason;
	}

	static isRegistrationError(error: Error) {
		return error instanceof RegistrationError;
	}

	static isAccountExistedError(error: RegistrationError) {
		return error.reason === RegistrationFailureReason.AccountExisted;
	}

	static isUnsupportedCountryError(error: RegistrationError) {
		return error.reason === RegistrationFailureReason.UnsupportedCountry;
	}
}
