const BASE_URL = import.meta.env.VITE_API_ENDPOINT || 'https://nmvs-sws-portal-be-dev.azurewebsites.net/api';

function getMaintenanceDueDateRoute() {
	return BASE_URL + '/portal-setting/maintenance/due';
}

function getReadinessRoute() {
	return BASE_URL + '/health/readiness';
}

function getHealthCheckRoute() {
	return BASE_URL + '/health';
}

export const apiRoutes = {
	getMaintenanceDueDate: () => getMaintenanceDueDateRoute(),
	getReadiness: () => getReadinessRoute(),
	getHealth: () => getHealthCheckRoute(),
};
