/* eslint-disable no-console */
import smartQueue from '@analytics/queue-utils';
import { AnalyticsEvent, AnalyticsQueueItem, ApiService } from 'services';
import io, { Socket } from 'socket.io-client';

// Queue options
interface QueueOptions {
	max: number;
	interval: number;
	throttle: boolean;
	onPause: () => void;
	onEmpty: (queue: AnalyticsQueueItem[], type: string) => void;
}

const defaultOpts: QueueOptions = {
	max: 10, // limit
	interval: 3000, // 3s
	throttle: true, // Ensure only max is processed at interval
	onPause: () => {
		console.log('queue paused');
	},
	onEmpty: (queue, type) => {
		if (!queue.length) {
			console.log('>>>>>>>> Queue empty! Halted processing', type);
		} else {
			console.log('Queue remaining', queue);
			console.log('start with queue.resume()');
		}
	},
};

export class AnalyticsQueue {
	private readonly apiService: ApiService;
	private readonly smartQueue: any;
	private readonly socket: Socket;

	constructor(apiService: ApiService, queueOpts?: Record<string, any>) {
		this.apiService = apiService;
		this.socket = io(`//${window.location.hostname}:3000`);
		this.socket.on(AnalyticsEvent.ACK, console.log);
		const _queueOptions = {
			...defaultOpts,
			...queueOpts,
		};
		this.smartQueue = smartQueue(this.processQueue, _queueOptions);
	}

	get queue() {
		return this.smartQueue;
	}

	get socketInstance() {
		return this.socket;
	}

	processQueue = (items: AnalyticsQueueItem[], restOfQueue: AnalyticsQueueItem[]) => {
		console.log('items to process', items);
		console.log('rest of queue', restOfQueue);
		Promise.all(items.map(item => this.socket.emit(item.eventName, item.data))).then(() => {
			console.log('batch processed');
		});
		if (this.apiService) {
			// do something
		}
	};

	closeSocket() {
		this.socket.off(AnalyticsEvent.ACK, console.log);
		return this.socket.close();
	}
}
