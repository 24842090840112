/**
 *
 * Asynchronously loads the component for NamsDocumentationPage
 *
 */

import { lazyLoad } from 'utils/loadable';

const NamsDocumentationPage = lazyLoad(
	() => import('./index'),
	module => module.NamsDocumentationPage
);

export default NamsDocumentationPage;
