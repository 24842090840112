/* eslint-disable no-console */
import { toJS } from 'mobx';
import { flow, getParent, Instance, types } from 'mobx-state-tree';
import { RootStore } from 'stores';
import { CreateStandardPackageDto } from 'utils/dtos/create-standard-package.dto';
import { ObservableState } from 'types/app.typings';

const StandardTestPackage = types.model('StandardTestPackage', {
	id: types.string,
	url: types.string,
	createdAt: types.string,
	testCaseName: types.string,
	testCaseDescription: types.string,
});

const StandardTestResult = types.model('StandardTestResult', {
	extTrxId: types.string,
	returnCode: types.string,
	businessProcessId: types.string,
	inputData: types.string,
	outputData: types.string,
	startDate: types.Date,
});

const EnhancedStandardTestPackage = types.model('EnhancedStandardTestPackage', {
	id: types.string,
	url: types.string,
	updatedAt: types.string,
	createdAt: types.string,
	productName: types.maybe(types.string),
	productVersion: types.maybe(types.string),
	testCaseName: types.string,
	testCaseDescription: types.string,
	expanded: types.boolean,
	loading: types.boolean,
	error: types.boolean,
	items: types.array(StandardTestResult),
	status: types.maybe(types.string),
});

export type EnhancedStandardTestPackageInstance = Instance<typeof EnhancedStandardTestPackage>;
export type StandardTestResultInstance = Instance<typeof StandardTestResult>;
export type StandardTestPackageInstance = Instance<typeof StandardTestPackage>;

export const StandardTestStore = types
	.model('StandardTestStore', {
		error: types.maybeNull(types.frozen()),
		state: types.enumeration(Object.values(ObservableState)),
		opState: types.enumeration(Object.values(ObservableState)),
		docs: types.array(EnhancedStandardTestPackage),
		_testResult: types.maybeNull(StandardTestResult),
		_testPackage: types.maybeNull(StandardTestPackage),
		lastUpdated: types.number,
	})
	.views(self => ({
		get isLoading() {
			return self.state === ObservableState.PENDING;
		},
		get isOpLoading() {
			return self.opState === ObservableState.PENDING;
		},
		get hasError() {
			return self.state === ObservableState.ERROR;
		},
		get hasOpError() {
			return self.opState === ObservableState.ERROR;
		},
		get errorInstance() {
			return self.error;
		},
		get docList(): any {
			return toJS(self.docs);
		},
		get testResult() {
			return self._testResult;
		},
		get createdTestPackage() {
			return self._testPackage;
		},
		get lastChanged() {
			return self.lastUpdated;
		},
		get rootStore(): any {
			return getParent<typeof RootStore>(self);
		},
		get promiseState() {
			return self.state;
		},
	}))
	.actions(self => ({
		process(data: any): any {
			// const root: RootStoreType = getRoot(self);
			const enhancedData = data.map((elem: Record<string, any>) => ({
				...elem,
				expanded: false,
				loading: false,
				error: false,
				items: [],
			}));
			self.lastUpdated = Date.now();
			self.docs = enhancedData;
			self.state = ObservableState.DONE;
			return enhancedData;
		},
	}))
	.actions(self => ({
		fetchDocs: flow(function* (): Generator<any, void, any> {
			self.state = ObservableState.PENDING;
			try {
				self.lastUpdated = Date.now();
				const docs = yield self.rootStore.api.getStandardPackages();
				return self.process(docs);
			} catch (e: any) {
				console.error('Unable to fetch standard test packages', e);
				self.error = e;
				self.lastUpdated = Date.now();
				self.state = ObservableState.ERROR;
			}
		}),
		toggleResult: flow<void, [index: number]>(function* (index: number): Generator<any, void, any> {
			const testPkg = self.docs[index];
			self.lastUpdated = Date.now(); // use to force rerender of baseline test result list
			if (testPkg) {
				try {
					if (testPkg.expanded) {
						testPkg.expanded = false;
						self.lastUpdated = Date.now();
					} else {
						self.lastUpdated = Date.now();
						testPkg.expanded = true;
						testPkg.loading = true;
						testPkg.error = false;
						self.state = ObservableState.PENDING;
						const data = yield self.rootStore.api.getStandardTestResult(testPkg.id);
						self.lastUpdated = Date.now();
						testPkg.loading = false;
						testPkg.items = data;
						self.state = ObservableState.DONE;
					}
				} catch (e: any) {
					console.error('Unable to fetch standard test result', e);
					self.error = e;
					testPkg.loading = false;
					testPkg.error = true;
					testPkg.items = [] as any;
					self.lastUpdated = Date.now();
					self.state = ObservableState.ERROR;
				}
			}
		}),
		createStandardPackage: flow<void, [data: CreateStandardPackageDto]>(function* (
			data: CreateStandardPackageDto
		): Generator<any, void, any> {
			self.opState = ObservableState.PENDING;
			try {
				self._testPackage = yield self.rootStore.api.createStandardTestPackage(data);
				self.opState = ObservableState.DONE;
			} catch (e: any) {
				console.error('Unable to create standard test package', e);
				self.error = e;
				self.opState = ObservableState.ERROR;
			}
		}),
		clearCreatedTestPackage() {
			self._testPackage = null;
		},
		setTestResult(result: StandardTestResultInstance | null) {
			self._testResult = result;
		},
		resetError() {
			self.lastUpdated = 0;
			self.error = null;
			self.state = ObservableState.INIT;
			self.opState = ObservableState.INIT;
		},
		resetOpState() {
			self.lastUpdated = 0;
			self.error = null;
			self.opState = ObservableState.INIT;
		},
		resetStore() {
			self.error = null;
			self.state = ObservableState.INIT;
			self.opState = ObservableState.INIT;
			self.lastUpdated = 0;
			self._testResult = null;
			self._testPackage = null;
			self.docs = [] as any;
		},
	}));

export interface StandardTestStoreType extends Instance<typeof StandardTestStore> {}
