/* eslint-disable no-console */
import { AuthStore } from 'auth';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { configService as config } from 'config';

export interface HttpError extends AxiosError {}

export class HttpService {
	private readonly authStore: AuthStore;

	constructor(authStore: AuthStore) {
		this.authStore = authStore;
	}

	getAxiosInstance(token: string | null): AxiosInstance {
		const instance = axios.create();
		instance.defaults.baseURL = config.apiEndpoint;
		instance.defaults.timeout = 300000; // Socket timeout to 5 minutes for some large baseline test package results
		(instance.defaults.headers as Record<string, any>).common.Authorization = `Bearer ${token}`;
		instance.interceptors.response.use(
			// catch 401 error to log out
			v => v,
			(error: AxiosError) => {
				if (error.response) {
					if (error.response.status === 401) {
						if (!this.authStore.authenticatedUser) {
							return Promise.reject(error.response.data ? error.response.data : error.response);
						}
						console.warn('Unauthorized, logging out...');
						this.authStore.logout();
						return Promise.reject(error.response.data ? error.response.data : error.response);
					}
					if (error.response.status === 504) {
						return Promise.reject({
							message: 'Service Unavailable',
							status: 504,
						});
					}
					return Promise.reject(error.response.data ? error.response.data : error.response);
				}
				return Promise.reject(error);
			}
		);
		return instance;
	}

	async get<T>(path: string, query: {} = {}, config?: AxiosRequestConfig): Promise<T> {
		const token = await this.authStore.accessToken;
		return this.getAxiosInstance(token)
			.get(path, { params: query, ...config })
			.then((response: AxiosResponse) => response.data) as Promise<T>;
	}

	async head<T>(path: string, query: {} = {}, config?: AxiosRequestConfig): Promise<T> {
		const token = await this.authStore.accessToken;
		return this.getAxiosInstance(token)
			.head(path, { params: query, ...config })
			.then((response: AxiosResponse) => response.headers) as Promise<T>;
	}

	async post<T>(path: string, data: any, config?: AxiosRequestConfig): Promise<T> {
		const token = await this.authStore.accessToken;
		return this.getAxiosInstance(token)
			.post(path, data, config)
			.then((response: AxiosResponse) => response.data) as Promise<T>;
	}

	async put<T>(path: string, data: any, config?: AxiosRequestConfig): Promise<T> {
		const token = await this.authStore.accessToken;
		return this.getAxiosInstance(token)
			.put(path, data, config)
			.then((response: AxiosResponse) => response.data) as Promise<T>;
	}

	async delete<T>(path: string, query: {} = {}, config?: AxiosRequestConfig): Promise<T> {
		const token = await this.authStore.accessToken;
		return this.getAxiosInstance(token)
			.delete(path, { params: query, ...config })
			.then((response: AxiosResponse) => response.data) as Promise<T>;
	}

	buildPath(path: string) {
		const appUrl = import.meta.env.VITE_API_ENDPOINT ? import.meta.env.VITE_API_ENDPOINT : '/api';
		return appUrl + path;
	}
}
