/**
 *
 * Asynchronously loads the component for DocumentationPage
 *
 */

import { lazyLoad } from 'utils/loadable';

const DocumentationPage = lazyLoad(
	() => import('./index'),
	module => module.DocumentationPage
);

export default DocumentationPage;
