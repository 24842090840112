export class AccountRejectionError extends Error {
	reason: string;
	message: string;

	constructor(reason: string, message: string) {
		super(message);
		this.reason = reason;
		this.message = message;
	}
}
