export const enum AnalyticsEvent {
	SWS_LOGIN = 'sws_login',
	DOCUMENT_DOWNLOAD = 'document_download',
	ACK = 'ack',
	ANALYTICS_EVENT = 'analytics_event',
}

export type AnalyticsQueueItem = {
	eventName: AnalyticsEvent | string;
	data: any;
};
