/**
 *
 * Asynchronously loads the component for ResetPasswordPage
 *
 */

import { lazyLoad } from 'utils/loadable';

const ResetPasswordPage = lazyLoad(
	() => import('./index'),
	module => module.ResetPasswordPage
);

export default ResetPasswordPage;
