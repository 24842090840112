export type Country = {
	name: string;
	code: string;
};

export type UserCredentials = {
	username: string;
	password: string;
};

export type RequestPasswordReset = {
	email: string;
	captcha: string;
};

export type PasswordReset = {
	password: string;
	passwordRepeat: string;
};

export enum OrganizationForm {
	COMMERCIAL_SWS = 'COMMERCIAL_SOFTWARE_SUPPLIER',
	INTERNAL_IT = 'INTERNAL_IT_DEPARTMENT',
}

export enum ActivityArea {
	Hospital = 'HOSPITAL',
	Wholesaler = 'WHOLESALER',
	Pharmacy = 'PHARMACY',
	Others = 'OTHERS',
}

export enum SwsDocumentType {
	MANUAL = 'MANUAL',
	TECH_DOC = 'TECH_DOC',
	WSDL = 'WSDL',
	LEGAL = 'LEGAL',
	OTHER = 'OTHER',
}

export enum SwsDocumentCategory {
	NMVS = 'NMVS',
	NAMS = 'NAMS',
}

export enum DocumentAzureStorageSubfolder {
	MANUAL = 'manual',
	TECH_DOC = 'techdoc',
	WSDL = 'wsdl',
	LEGAL = 'legal',
	OTHER = 'otherdoc',
}

export enum RegistrationStatus {
	PENDING_EMAIL = 'PENDING_EMAIL_CONFIRMATION',
	PENDING_SP_FETCH = 'PENDING_SP_FETCH',
	PENDING_APPROVAL = 'PENDING_APPROVAL',
	ACCEPTED = 'ACCEPTED',
	REJECTED = 'REJECTED',
	PERMANENTLY_REJECTED = 'REJECTED_PERMANENTLY',
}

export enum BaselineTestStatus {
	IN_PROGRESS = 'IN_PROGRESS',
	CERTIFICATION_REQUESTED = 'CERTIFICATION_REQUESTED',
	ACCEPTED = 'CERTIFIED',
	REJECTED = 'CERTIFICATION_DENIED',
}

export enum RejectionReason {
	INACTIVE_SWS = 'INACTIVE_SOFTWARE_SUPPLIER',
	MULTI_ACCOUNTS = 'MULTIPLE_ACCOUNTS',
	INFO_INSUFFICIENT = 'INSUFFICIENT_INFORMATION',
}

export enum ObservableState {
	INIT = 'init',
	PENDING = 'pending',
	DONE = 'done',
	ERROR = 'error',
}

export enum ResetPasswordState {
	TOKEN_VALID = 'token_valid',
	TOKEN_INVALID = 'token_invalid',
	RESET_SUCCESS = 'reset_success',
	PASSWORD_POLICY_UNMET = 'password_policy',
	ACCOUNT_NOT_EXISTED = 'account_not_existed',
	INIT = 'init',
}

export enum FooterType {
	PORTAL = 'portal',
	LANDING_PAGE = 'landingPage',
}

export type AuthenticatedUser = {
	softwareCountry: UserSoftwareCountry[];
	selectedSoftwareCountry: UserSoftwareCountry;
	salutation: string;
	email: string;
	firstName: string;
	lastName: string;
	token: string;
	downloadToken: string;
	activateNewsletter: boolean;
	sandbox1UsernameAvailable: boolean;
	sandbox2UsernameAvailable: boolean;
	lastSignIn: Date;
	createdAt: Date;
};

export type UserProfile = {
	nmvs_UserName: string | null;
	nmvs_Sandbox2_UserName: string | null;
	nmvsUserNameCreatedAt: string | null;
	nmvsSandbox2UserNameCreatedAt: string | null;
	lastSignIn: string | null;
	address: {
		street: string;
		addressComplement: string;
		zip: string;
		city: string;
		country: string;
	};
	concentrator: boolean;
	activateNewsletter: boolean;
	salutation: string;
	companyName: string;
	firstName: string;
	lastName: string;
	phone: string;
	website: string;
	email: string;
	organizationForm: string;
	keyClients: string;
	softwareCountry: string[];
	activityAreas: string[];
	activityAreaHospitalRemark: string;
	activityAreaOtherRemark: string;
	activityAreaPharmacyRemark: string;
	activityAreaWholesalerRemark: string;
	createdAt: string;
};

export interface UserSoftwareCountry {
	status: RegistrationStatus | string;
	countryCode: string;
	countryFullName: string;
}

export type NewsAndFaq = {
	title: string;
	content: string;
	publishedAt: string;
	category?: string;
	sortingPriority: number;
	updatedAt: string;
	isPublished: boolean;
};

export enum PusherChannel {
	NmvsUsers = 'nmvs_admin_portal_v2__nmvs_users',
	SwsDocuments = 'nmvs_admin_portal_v2__sws_documents',
	BaselineTesting = 'nmvs_admin_portal_v2__baseline_testing',
	NmvsAdmins = 'nmvs_admin_portal_v2__nmvs_admins',
	AuditLogs = 'nmvs_admin_portal_v2__audit_logs',
	ApprovalTasks = 'nmvs_admin_portal_v2__approval_tasks',
	EmailTraffic = 'nmvs_admin_portal_v2__email_traffic',
}

export enum PusherEvent {
	OnNmvsUserUpdated = 'nmvs_users__on_user_updated',
	OnNmvsUserListLoadingProgress = 'nmvs_users__on_nmvs_user_list_loading_progress',
	OnSwsDocumentListLoadingProgress = 'sws_documents__on_sws_document_list_loading_progress',
	OnSwsDocumentUpdated = 'sws_documents__on_document_updated',
	OnSwsDocumentCreated = 'sws_documents__on_document_created',
	OnSwsDocumentDeleted = 'sws_documents__on_document_deleted',
	OnBaselineStatusUpdated = 'baseline_testing__on_baseline_status_updated',
	OnBaselineStatusLoadingProgress = 'baseline_testing__on_baseline_status_loading_progress',
	OnNmvsAdminListLoadingProgress = 'nmvs_admins__on_nmvs_admin_list_loading_progress',
	OnAuditLogLoadingProgress = 'audit_logs__on_audit_log_loading_progress',
	OnBaselineTestProtocolUploaded = 'baseline_testing__on_baseline_test_protocol_uploaded',
	OnApprovalTaskLoadingProgress = 'approval_tasks__on_approval_task_loading_progress',
	OnApprovalTaskUpdated = 'approval_tasks__on_approval_task_updated',
	OnRegConfirmEmailSent = 'email_traffic__on_reg_confirm_email_sent',
}

export interface FileAttachment {
	fileId: string;
	fileName: string;
	fileType: string;
	fileSize: string;
	fileExt: string;
	fileReadableSize: string;
	filePreview: {
		type: string;
		url?: string;
	};
	base64: string;
}

export interface SwsDocument {
	docType: string;
	docCategory: string;
	docFileType: string;
	docSize: number;
	docTitle: string;
	docUri: string;
	docPublishedAt: string;
	updatedAt: string;
	sortingPriority: number;
	relatedRelease: string;
	docDescription: string | null;
}

export type TestResultLog = {
	extTrxId: string;
	returnCode: string;
	businessProcessId: string;
	inputData: string;
	outputData: string;
	startDate: Date;
};

export enum CreateTestPackageContainerState {
	CHOOSE_SANDBOX = 'SELECT_ENV',
	INITIAL = 'INITIAL',
	CREATION = 'CREATION',
	DOWNLOAD = 'DOWNLOAD',
	REQUEST_NMVS_USERNAME_SUBMITTED = 'REQUEST_NMVS_USERNAME_SUBMITTED',
	ERROR = 'ERROR',
}

export type TestResultList = TestResultLog[];
export enum SandboxEnvironment {
	ONE = '1',
	TWO = '2',
}

export enum CustomForbiddenReason {
	LoginFailure = 'login_failure',
	PendingConfirmation = 'pending_confirmation',
	PendingFetch = 'pending_fetch',
	PendingApproval = 'pending_approval',
	InactiveSws = 'inactive_sws',
	MultiAccounts = 'multi_accounts',
	InsufficientInfo = 'insufficient_info',
	GeneralRejection = 'general_rejection',
}

export enum RegistrationFailureReason {
	AccountExisted = 'account_existed',
	UnsupportedCountry = 'unsupported_country',
}

export type BaselineTestPackage = {
	id: string;
	url: string;
	createdAt: string;
	testCaseName: string;
	productName: string;
	productVersion: string;
	testCaseDescription: string;
	endCustomers: string[];
};

export type StandardTestPackage = {
	id: string;
	url: string;
	createdAt: string;
	testCaseName: string;
	testCaseDescription: string;
};

export type VideoAsset = {
	url?: string;
	thumbnail?: any;
	title: string;
	description?: string;
	alt?: string;
};

export type SelectOption = {
	label: string;
	value: string;
	disabled: boolean;
	isSelected: boolean;
};

export enum PortalSetting {
	BASELINE_TESTING_DOCUMENTS = 'baselineTestingDocuments',
	SWS_PORTAL_MAINTENANCE_MODE = 'swsPortalMaintenanceMode',
	ADMIN_PORTAL_MAINTENANCE_MODE = 'adminPortalMaintenanceMode',
	SWS_PORTAL_MAINTENANCE_DUE_DATE = 'swsPortalMaintenanceDueDate',
	ADMIN_PORTAL_MAINTENANCE_DUE_DATE = 'adminPortalMaintenanceDueDate',
	SWS_CONTACT_FORM_ANNOUNCEMENT = 'swsContactFormAnnouncement',
}

export enum AzureBlobIndexTags {
	MALWARE_SCANNING_SCAN_RESULT = 'Malware Scanning scan result',
	MALWARE_SCANNING_SCAN_TIME_UTC = 'Malware Scanning scan time UTC',
}

export enum AzureBlobMalwareScanResults {
	NO_THREATS_FOUND = 'No threats found',
}

export const START_DATE_MALWARE_SCANNING = new Date('2024-06-01');

export const FallbackTranslations = {
	REQUIRED_ERROR: 'This field is required',
	INVALID_TYPE_ERROR: 'Invalid input',
	EMAIL_INVALID: 'Invalid email address',
	URL_INVALID: 'Invalid URL',
	TOC_ERROR: 'You must accept the terms and conditions',
	PASSWORD_NOT_MATCH: 'Passwords do not match',
	MAX_LENGTH_ERROR: (length: number) => `Maximum length is ${length}`,
	MIN_LENGTH_ERROR: (length: number) => `Minimum length is ${length}`,
};

export const TOOLTIP_DELAY = import.meta.env.VITE_TOOLTIP_DELAY || 1000;
