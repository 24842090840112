/**
 *
 * Asynchronously loads the component for TutorialPage
 *
 */

import { lazyLoad } from 'utils/loadable';

const TutorialPage = lazyLoad(
	() => import('./index'),
	module => module.TutorialPage
);

export default TutorialPage;
