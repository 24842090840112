/**
 *
 * Asynchronously loads the component for Tooltip
 *
 */

import { lazyLoad } from 'utils/loadable';

const Tooltip = lazyLoad(
	() => import('./index'),
	module => module.Tooltip
);

export default Tooltip;
