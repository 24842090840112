/* eslint-disable no-console */
import { toJS } from 'mobx';
import { flow, getParent, Instance, types } from 'mobx-state-tree';
import { RootStore, SelectOptionType } from 'stores';
import { ObservableState, SwsDocument, SwsDocumentCategory, SwsDocumentType } from 'types/app.typings';
import { compareSelectOption } from 'utils/common';
import { DocumentDownloadTelemetryDto } from 'utils/dtos/register-telemetry.dto';

const compareDesc = (a: SwsDocument, b: SwsDocument) => {
	return Date.parse(b.docPublishedAt) - Date.parse(a.docPublishedAt);
};

const DocumentItem = types.model('DocumentItem', {
	docType: types.string,
	docCategory: types.string,
	docFileType: types.string,
	docSize: types.number,
	docTitle: types.string,
	docUri: types.string,
	docPublishedAt: types.string,
	updatedAt: types.string,
	sortingPriority: types.number,
	relatedRelease: types.string,
	docDescription: types.maybeNull(types.string),
});

export type DocumentItemInstance = Instance<typeof DocumentItem>;

export const DocumentStore = types
	.model('DocumentStore', {
		error: types.maybeNull(types.frozen()),
		state: types.enumeration(Object.values(ObservableState)),
		opState: types.enumeration(Object.values(ObservableState)),
		docs: types.array(DocumentItem),
		relatedReleaseOptions: types.array(SelectOptionType),
	})
	.views(self => ({
		get isLoading() {
			return self.state === ObservableState.PENDING;
		},
		get isOpLoading() {
			return self.opState === ObservableState.PENDING;
		},
		get hasError() {
			return self.state === ObservableState.ERROR;
		},
		get hasOpError() {
			return self.opState === ObservableState.ERROR;
		},
		get docList() {
			return self.docs;
		},
		get rootStore(): any {
			return getParent<typeof RootStore>(self);
		},
		get promiseState() {
			return self.state;
		},
		get relatedReleaseOpts() {
			return toJS(self.relatedReleaseOptions);
		},
		get manuals() {
			return toJS(self.docs).filter(
				doc => doc.docType === SwsDocumentType.MANUAL && doc.docCategory === SwsDocumentCategory.NMVS
			);
		},
		get techDocs() {
			return toJS(self.docs).filter(
				doc => doc.docType === SwsDocumentType.TECH_DOC && doc.docCategory === SwsDocumentCategory.NMVS
			);
		},
		get wsdls() {
			return toJS(self.docs).filter(
				doc => doc.docType === SwsDocumentType.WSDL && doc.docCategory === SwsDocumentCategory.NMVS
			);
		},
		get legals() {
			return toJS(self.docs).filter(
				doc => doc.docType === SwsDocumentType.LEGAL && doc.docCategory === SwsDocumentCategory.NMVS
			);
		},
		get others() {
			return toJS(self.docs).filter(
				doc => doc.docType === SwsDocumentType.OTHER && doc.docCategory === SwsDocumentCategory.NMVS
			);
		},
		get manualsNAMS() {
			return toJS(self.docs).filter(
				doc => doc.docType === SwsDocumentType.MANUAL && doc.docCategory === SwsDocumentCategory.NAMS
			);
		},
		get techDocsNAMS() {
			return toJS(self.docs).filter(
				doc => doc.docType === SwsDocumentType.TECH_DOC && doc.docCategory === SwsDocumentCategory.NAMS
			);
		},
		get wsdlsNAMS() {
			return toJS(self.docs).filter(
				doc => doc.docType === SwsDocumentType.WSDL && doc.docCategory === SwsDocumentCategory.NAMS
			);
		},
		get legalsNAMS() {
			return toJS(self.docs).filter(
				doc => doc.docType === SwsDocumentType.LEGAL && doc.docCategory === SwsDocumentCategory.NAMS
			);
		},
		get othersNAMS() {
			return toJS(self.docs).filter(
				doc => doc.docType === SwsDocumentType.OTHER && doc.docCategory === SwsDocumentCategory.NAMS
			);
		},
		latestDoc(type: string) {
			return toJS(self.docs)
				.filter(doc => doc.docType === type)
				.sort(compareDesc)
				.slice(0, 2);
		},
		latestDocNAMS(type: string) {
			return toJS(self.docs)
				.filter(doc => doc.docType === type && doc.docCategory === SwsDocumentCategory.NAMS)
				.sort(compareDesc)
				.slice(0, 2);
		},
		docsForRelease(docs: DocumentItemInstance[], release: string) {
			return docs.filter(doc => doc.relatedRelease === release);
		},

		docsForReleaseNAMS(docs: DocumentItemInstance[], release: string) {
			return docs.filter(doc => doc.relatedRelease === release && doc.docCategory === SwsDocumentCategory.NAMS);
		},
	}))
	.actions(self => ({
		process(data: any): any {
			// const root: RootStoreType = getRoot(self);
			self.docs = data;
			self.state = ObservableState.DONE;
			return data;
		},
	}))
	.actions(self => ({
		registerDownloadTelemetry: flow<void, [data: DocumentDownloadTelemetryDto]>(function* (
			data: DocumentDownloadTelemetryDto
		) {
			try {
				yield self.rootStore.api.registerDownloadTelemetry(data);
			} catch (e: any) {
				console.error('Register telemetry failed', e);
			}
		}),
		fetchDocs: flow<void, []>(function* () {
			self.state = ObservableState.PENDING;
			try {
				const docs = yield self.rootStore.api.getSwsDocuments();
				return self.process(docs);
			} catch (e: any) {
				console.error('Unable to fetch documents', e);
				self.error = e;
				self.state = ObservableState.ERROR;
			}
		}),
		fetchRelatedReleases: flow<boolean, []>(function* () {
			self.opState = ObservableState.PENDING;
			try {
				const options = yield self.rootStore.api.getRelatedReleases();
				self.relatedReleaseOptions = options
					.map((option: Record<string, any>) => ({
						...option,
						disabled: false,
						isSelected: false,
					}))
					.sort(compareSelectOption);
				self.opState = ObservableState.DONE;
				return true;
			} catch (e: any) {
				console.error('Unable to fetch related releases', e);
				self.error = e;
				self.opState = ObservableState.ERROR;
				return false;
			}
		}),
		resetError() {
			self.error = null;
			self.state = ObservableState.INIT;
			self.opState = ObservableState.INIT;
		},
		resetOpState() {
			self.error = null;
			self.opState = ObservableState.INIT;
		},
		resetStore() {
			self.error = null;
			self.state = ObservableState.INIT;
			self.opState = ObservableState.INIT;
			self.docs = [] as any;
			self.relatedReleaseOptions = [] as any;
		},
	}));

export interface DocumentStoreType extends Instance<typeof DocumentStore> {}
