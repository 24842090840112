export interface ValidationErrorLike {
	error?: string;
	message: string[];
	statusCode: number;
}

export class ValidationError extends Error {
	validationsErrors: string[];
	statusCode: number;

	constructor(validationsErrors: string[], message: string = 'Invalid input/Validation error', statusCode: number) {
		super(message);
		this.validationsErrors = validationsErrors;
		this.statusCode = statusCode;
	}

	static isValidationErrorLike(error: ValidationErrorLike) {
		return (
			error &&
			error.statusCode &&
			Array.isArray(error.message) &&
			error.message.length > 0 &&
			typeof error.message[0] === 'string'
		);
	}

	static createFromValidationErrorLike(error: ValidationErrorLike) {
		return new ValidationError(error.message, undefined, error.statusCode);
	}
}
