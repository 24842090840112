import { useMutation, useQuery, useQueryClient, UseQueryOptions, QueryFunctionContext } from '@tanstack/react-query';
import { httpService } from '../meta-services/httpServices';
import { AxiosError, AxiosResponse } from 'axios';

type QueryKeyT = [string, object | undefined];
type PageParamT = number | undefined;

export const fetcher = <T>({
	queryKey,
	// signal,
	pageParam,
	// meta,
}: QueryFunctionContext<QueryKeyT, PageParamT>): Promise<T> => {
	const [url, params] = queryKey;
	return httpService.get<T>(url, { params: { ...params, pageParam } }).then(res => res.data);
};

export const usePrefetch = <T>(url: string | null, params?: object) => {
	const queryClient = useQueryClient();

	return () => {
		if (!url) {
			return;
		}
		const controller = new AbortController();
		const { signal } = controller;

		queryClient
			.prefetchQuery<T, Error, T, QueryKeyT>({
				queryKey: [url!, params],
				queryFn: ({ queryKey }) => fetcher({ queryKey, signal, pageParam: 0, direction: 'forward', meta: undefined }),
			})
			.then(r => console.log('query prefetched!', r));
	};
};

export const useFetch = <T>(
	url: string | null,
	params?: object,
	config?: Omit<UseQueryOptions<T, Error, T, QueryKeyT>, 'queryKey'>
) => {
	const controller = new AbortController();
	const { signal } = controller;

	return useQuery<T, Error, T, QueryKeyT>({
		queryKey: [url!, params],
		queryFn: ({ queryKey }) => fetcher({ queryKey, signal, pageParam: 0, direction: 'forward', meta: undefined }),
		enabled: !!url,
		...config,
	});
};

const useGenericMutation = <T, S>(
	func: (data: T | S) => Promise<AxiosResponse<S>>,
	url: string,
	params?: object,
	updater?: ((oldData: T, newData: S) => T) | undefined
) => {
	const queryClient = useQueryClient();

	return useMutation<AxiosResponse, AxiosError, T | S>({
		mutationFn: func,
		onMutate: async (data: any) => {
			await queryClient.cancelQueries({ queryKey: [url!, params] });

			const previousData = queryClient.getQueryData([url!, params]);

			queryClient.setQueryData<T>([url!, params], oldData => {
				return updater ? updater(oldData!, data as S) : (data as T);
			});

			return previousData;
		},
		onError: (err: any, _: any, context: any) => {
			console.log('Mutation error:', err);
			queryClient.setQueryData([url!, params], context);
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: [url!, params] }).then(() => console.warn('query invalidated'));
		},
	});
};

export const useDelete = <T>(url: string, params?: object, updater?: (oldData: T, id: string | number) => T) => {
	return useGenericMutation<T, string | number>(id => httpService.delete(`${url}/${id}`), url, params, updater);
};

export const usePost = <T, S>(url: string, params?: object, updater?: (oldData: T, newData: S) => T) => {
	return useGenericMutation<T, S>(data => httpService.post<S>(url, data), url, params, updater);
};

export const useUpdate = <T, S>(url: string, params?: object, updater?: (oldData: T, newData: S) => T) => {
	return useGenericMutation<T, S>(data => httpService.patch<S>(url, data), url, params, updater);
};
