class ConfigService {
	get msalConfig_Authority() {
		return import.meta.env.VITE_AUTHORITY as string;
	}

	get msalConfig_ClientId() {
		return import.meta.env.VITE_CLIENT_ID as string;
	}

	get msalConfig_RedirectUri() {
		return import.meta.env.VITE_REDIRECT_URI as string;
	}

	get msalConfig_Scopes() {
		if (import.meta.env.PROD) {
			return ['openid', 'profile', 'https://nmvs-sws-portal-prod-application.azurewebsites.net/.default'];
		} else {
			return ['openid', 'profile', 'https://nmvs-sws-portal-dev-application.azurewebsites.net/.default'];
		}
	}

	get isDev() {
		return import.meta.env.DEV;
	}

	get apiEndpoint() {
		return import.meta.env.VITE_API_ENDPOINT || '/api';
	}

	get debounceDelay() {
		return parseInt(import.meta.env.VITE_DEBOUNCE_DELAY_MS || '1000');
	}

	get recaptchaSitekey() {
		return import.meta.env.VITE_RECAPTCHA_SITEKEY || '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
	}
}

export const configService = new ConfigService();
