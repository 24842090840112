/* eslint-disable no-console */

import { AnalyticsQueue, ApiService } from 'services';

type NmvsAnalyticsProviderPluginUserConfig = {
	debug: boolean;
	enabled: boolean;
};

type NmvsAnalyticsProviderPluginConfig = NmvsAnalyticsProviderPluginUserConfig & {
	dataLayer: AnalyticsQueue | undefined;
};

export const defaultConfig: NmvsAnalyticsProviderPluginConfig = {
	debug: true,
	enabled: true,
	dataLayer: undefined,
};

export type AnalyticsParams = {
	payload: any;
	options: any;
	instance: any;
	config: NmvsAnalyticsProviderPluginConfig;
};

export const NmvsAnalyticsProviderPlugin = (
	apiService: ApiService,
	userConfig: NmvsAnalyticsProviderPluginUserConfig
): any => {
	// return object for analytics to use
	return {
		/* All plugins require a name */
		name: 'NmvsAnalyticsProviderPlugin',
		/* Everything else below this is optional depending on your plugin requirements */
		config: {
			...defaultConfig,
			...userConfig,
		},
		initialize: ({ config }: AnalyticsParams) => {
			// load provider script to page
			config.dataLayer = new AnalyticsQueue(apiService);
			console.warn('Provider script initialized', config);
		},
		page: ({ payload, options, ...args }: AnalyticsParams) => {
			// call provider specific page tracking
			const { config } = args;
			if (config.debug) {
				console.warn('Page tracking called', payload, options);
			}
		},
		track: ({ payload, options, config }: AnalyticsParams) => {
			// call provider specific event tracking
			if (config.debug) {
				console.warn('Event tracking called', payload, options, config);
			}

			if (config.enabled) {
				const { event, properties } = payload;

				if (config.debug) {
					console.warn('event', event);
					console.warn('properties', properties);
				}

				// config.dataLayer?.emitEvent(event, properties);
				config.dataLayer?.queue.push(properties);
			}
		},
		identify: ({ ...args }: any) => {
			// call provider specific user identify method
			console.warn('User identification called', args);
		},
		loaded: () => {
			// return boolean so analytics knows when it can send data to third party
			// return !!(window as any).myPluginLoaded
			return true;
		},
	};
};
