import { authModule } from 'auth';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { validateOrReject } from 'class-validator';
import { encodeBase64 } from 'utils/base64';
import { CreateBaselinePackageDto } from 'utils/dtos/create-baseline-package.dto';
import { CreateStandardPackageDto } from 'utils/dtos/create-standard-package.dto';
import { ExtraCountryRegistrationDto } from 'utils/dtos/extra-country-registration.dto';
import { NmvsCountryDto } from 'utils/dtos/nmvs-country.dto';
import { ApiResponseSwsFaqDto, FaqQuery } from 'utils/dtos/sws-faq.dto';
import { ApiResponseSwsNewsDto, NewsQuery } from 'utils/dtos/sws-news.dto';
import { ApiResponseTestResultLogDto } from 'utils/dtos/test-result-log.dto';
import { UserProfileDto } from 'utils/dtos/user-profile.dto';
import { EmailAlreadyConfirmedError, InvalidTokenError } from 'utils/errors';
import {
	AuthenticatedUser,
	BaselineTestPackage,
	RequestPasswordReset,
	StandardTestPackage,
	SwsDocument,
	TestResultList,
	UserCredentials,
	UserProfile,
} from 'types/app.typings';
import { HttpService } from './http.service';

export interface IClientApi {
	login: Function;
	requestPasswordReset: Function;
	verifyPasswordResetToken: Function;
	resetPassword: Function;
	confirmEmail: Function;
	register: Function;
	sendContactForm: Function;
	requestSandboxUsername: Function;
	subscribeNewsletter: Function;
	getProfile: Function;
	changeSwCountry: Function;
	registerExtraCountry: Function;
	searchFaqs: Function;
	getFaqCategories: Function;
	getNews: Function;
	getNmvsCountries: Function;
	getSwsDocuments: Function;
	getRelatedReleases: Function;
	getStandardPackages: Function;
	getStandardTestResult: Function;
	createStandardTestPackage: Function;
	createBaselineTestPackage: Function;
	uploadBaselineTestProtocol: Function;
	getBaselineStatuses: Function;
	getBaselineTestResult: Function;
	getBaselineDocs: Function;
	getSwsNews: Function;
	registerDownloadTelemetry: Function;
	createBaselineProtocol: Function;
	getPortalMaintenanceMode: Function;
	getSwsContactFormAnnouncement: Function;
}

export class ApiService implements IClientApi {
	private readonly http: HttpService;
	constructor(httpService: HttpService) {
		this.http = httpService;
	}

	// SWS User

	public async login(credentials: UserCredentials): Promise<AuthenticatedUser> {
		try {
			return await this.http.post<AuthenticatedUser>('/user/auth/login', credentials);
		} catch (error: any) {
			throw error;
		}
	}

	public async requestPasswordReset(data: RequestPasswordReset, captcha = ''): Promise<void> {
		try {
			const config = {
				headers: {
					Recaptcha: captcha,
				},
			};
			await this.http.post<void>('/user/password/forgot', data, config);
		} catch (error: any) {
			throw error;
		}
	}

	public async verifyPasswordResetToken(token: string): Promise<void> {
		try {
			await this.http.get<void>('/user/password/verify-token', {
				t: token,
			});
		} catch (error: any) {
			if (error.message && error.message.includes('Invalid token')) {
				throw new InvalidTokenError();
			}
			throw error;
		}
	}

	public async resetPassword(token: string, password: string): Promise<void> {
		try {
			await this.http.post<void>('/user/password/reset', {
				token,
				password,
			});
		} catch (error: any) {
			throw error;
		}
	}

	public async confirmEmail(email: string, token: string): Promise<void> {
		try {
			const base64 = encodeBase64(JSON.stringify({ user: email, token }));
			await this.http.get<void>('/user/confirm', {
				q: base64,
			});
			return;
		} catch (error: any) {
			if (error.message && error.message.includes('Email is already confirmed')) {
				throw new EmailAlreadyConfirmedError();
			}
			throw error;
		}
	}

	public async register(data: Record<string, any>, captcha = ''): Promise<void> {
		try {
			const config = {
				headers: {
					Recaptcha: captcha,
				},
			};
			return await this.http.post<void>('/user/register', data, config);
		} catch (error: any) {
			throw error;
		}
	}

	public async sendContactForm(isAuthenticated = false, data: Record<string, any>, captcha = ''): Promise<void> {
		try {
			const config = {
				headers: {
					Recaptcha: captcha,
				},
			};
			if (isAuthenticated) {
				await this.http.post<void>('/user/contact/auth', data);
			} else {
				await this.http.post<void>('/user/contact/no_auth', data, config);
			}
		} catch (error: any) {
			throw error;
		}
	}

	public async requestSandboxUsername(sandbox: number): Promise<void> {
		try {
			await this.http.post<void>('/user/sandbox/request-username', { sandbox });
		} catch (error: any) {
			throw error;
		}
	}

	public async subscribeNewsletter(optIn: boolean): Promise<void> {
		try {
			await this.http.put<void>('/user/newsletter/subscribe', { optIn });
		} catch (error: any) {
			throw error;
		}
	}

	public async getProfile(email: string): Promise<UserProfile> {
		try {
			const profile = await this.http.get<UserProfileDto>('/user/profile', { em: email });
			const classProfile = plainToInstance(UserProfileDto, profile);
			await validateOrReject(classProfile);
			return instanceToPlain<UserProfileDto>(classProfile) as UserProfile;
		} catch (e: any) {
			throw e;
		}
	}

	public async changeSwCountry(code: string): Promise<AuthenticatedUser> {
		try {
			const base64 = encodeBase64(JSON.stringify({ selectedCountry: code }));
			return await this.http.get<AuthenticatedUser>('/user/auth/token', {
				q: base64,
			});
		} catch (e: any) {
			throw e;
		}
	}

	public async registerExtraCountry(data: ExtraCountryRegistrationDto): Promise<AuthenticatedUser> {
		try {
			return await this.http.post<AuthenticatedUser>('/user/country/register', data);
		} catch (error: any) {
			throw error;
		}
	}

	// FAQs

	public async searchFaqs(search: FaqQuery): Promise<Record<string, any>> {
		try {
			const res = await this.http.post<ApiResponseSwsFaqDto>('/faq/search', search);
			const resClass = plainToInstance(ApiResponseSwsFaqDto, res);
			await validateOrReject(resClass);
			return instanceToPlain<ApiResponseSwsFaqDto>(resClass);
		} catch (e: any) {
			throw e;
		}
	}

	public async getFaqCategories(): Promise<string[]> {
		try {
			return await this.http.get<string[]>('/faq/categories');
		} catch (e: any) {
			throw e;
		}
	}

	// News

	public async getNews(query: NewsQuery): Promise<Record<string, any>> {
		try {
			const base64 = encodeBase64(JSON.stringify(query));
			const res = await this.http.get<ApiResponseSwsNewsDto>('/news', {
				q: base64,
			});
			const resClass = plainToInstance(ApiResponseSwsNewsDto, res);
			await validateOrReject(resClass);
			return instanceToPlain<ApiResponseSwsNewsDto>(resClass);
		} catch (e: any) {
			throw e;
		}
	}

	public async getNmvsCountries(): Promise<Record<string, any>[]> {
		try {
			const res = await this.http.get<NmvsCountryDto[]>('/countries');
			return await Promise.all(
				res.map(async item => {
					const country = plainToInstance(NmvsCountryDto, item);
					await validateOrReject(country);
					return instanceToPlain<NmvsCountryDto>(country);
				})
			);
		} catch (e: any) {
			throw e;
		}
	}

	// SWS Document

	public async getSwsDocuments(): Promise<SwsDocument[]> {
		try {
			return await this.http.get<SwsDocument[]>('/sws-docs');
		} catch (e: any) {
			throw e;
		}
	}

	public async getRelatedReleases(): Promise<{ label: string; value: string }[]> {
		try {
			return await this.http.get<{ label: string; value: string }[]>('/sws-docs/releases');
		} catch (e: any) {
			throw e;
		}
	}

	// Standard Test

	public async getStandardPackages(): Promise<Record<string, any>[]> {
		try {
			const res = await this.http.get<Record<string, any>[]>('/std-test-package/4user');
			return res.map(item => {
				item.url = this.http.buildPath(`/std-test-package/download/${item.id}?token=${authModule.downloadToken}`);
				return item;
			});
		} catch (e: any) {
			throw e;
		}
	}

	public async getStandardTestResult(packageId: string, sandbox?: number): Promise<TestResultList> {
		try {
			const queryBase64 = encodeBase64(JSON.stringify({ packageId, sandbox }));
			const response = await this.http.get<Array<ApiResponseTestResultLogDto>>('/std-test-package/result', {
				q: queryBase64,
			});
			return response.map(result => ({
				...result,
				startDate: new Date(result.startDate),
			}));
		} catch (e: any) {
			throw e;
		}
	}

	public async createStandardTestPackage(payload: CreateStandardPackageDto) {
		try {
			const testPackage: StandardTestPackage = await this.http.post('/std-test-package/create', payload);
			testPackage.url = this.http.buildPath(
				`/std-test-package/download/${testPackage.id}?token=${authModule.downloadToken}`
			);
			return testPackage;
		} catch (e: any) {
			throw e;
		}
	}

	// Baseline Test

	public async getTestStatusById(id: string) {
		try {
			const queryBase64 = encodeBase64(JSON.stringify({ id }));
			return await this.http.get<any>('/baseline/status/one', {
				q: queryBase64,
			});
		} catch (e: any) {
			throw e;
		}
	}

	public async createBaselineTestPackage(payload: CreateBaselinePackageDto) {
		try {
			const baselineTestPackage: BaselineTestPackage = await this.http.post('/baseline/package/create', payload);
			baselineTestPackage.url = this.http.buildPath(
				`/baseline/package/download/${baselineTestPackage.id}?token=${authModule.downloadToken}`
			);
			return baselineTestPackage;
		} catch (e: any) {
			throw e;
		}
	}

	public async uploadBaselineTestProtocol(data: FormData): Promise<void> {
		try {
			await this.http.post<void>('/baseline/status/upload', data);
		} catch (e: any) {
			throw e;
		}
	}

	public async getBaselineStatuses(): Promise<Record<string, any>[]> {
		try {
			return await this.http.get<Record<string, any>[]>('/baseline/status/4user');
		} catch (e: any) {
			throw e;
		}
	}

	public async getBaselineTestResult(packageId: string): Promise<TestResultList> {
		try {
			const queryBase64 = encodeBase64(JSON.stringify({ packageId }));
			const response = await this.http.get<ApiResponseTestResultLogDto[]>('/baseline/package/result', {
				q: queryBase64,
			});
			if (Array.isArray(response) && response.length <= 0) {
				return [];
			}
			return response.map(result => ({
				...result,
				startDate: new Date(result.startDate),
			}));
		} catch (e: any) {
			throw e;
		}
	}

	public async getBaselineDocs(): Promise<Record<string, any>> {
		try {
			return await this.http.get<Array<ApiResponseTestResultLogDto>>('/sws-docs/baseline');
		} catch (e: any) {
			throw e;
		}
	}

	// SWS News

	public async getSwsNews(query: any): Promise<ApiResponseSwsNewsDto> {
		try {
			const base64 = encodeBase64(JSON.stringify(query));
			const _apiRes = await this.http.get<ApiResponseSwsNewsDto>('/sws-news', {
				q: base64,
			});
			const apiRes = plainToInstance(ApiResponseSwsNewsDto, _apiRes);
			await validateOrReject(apiRes);
			return apiRes;
		} catch (e: any) {
			throw e;
		}
	}

	// Telemetry

	public async registerDownloadTelemetry(data: Record<string, any>): Promise<void> {
		try {
			await this.http.post<void>('/sws-docs/register-telemetry', data);
		} catch (e: any) {
			throw e;
		}
	}

	// Baseline Protocol

	public async createBaselineProtocol(data: Record<string, any>): Promise<void> {
		try {
			await this.http.post<void>('/baseline/protocol/create', data);
		} catch (e: any) {
			throw e;
		}
	}

	// Portal setting (maintenance mode)
	public async getPortalMaintenanceMode(): Promise<boolean> {
		try {
			return await this.http.get<boolean>('/portal-setting/maintenance');
		} catch (e: any) {
			throw e;
		}
	}

	// Portal setting (contact form announcement)
	public async getSwsContactFormAnnouncement(): Promise<string> {
		try {
			return await this.http.get<string>('/portal-setting/contact-form-announcement');
		} catch (e: any) {
			throw e;
		}
	}
}
