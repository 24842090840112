import { createWithEqualityFn as create } from 'zustand/traditional';
import { persist, createJSONStorage } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export type BaselineProtocolFormStoreProps = {
	set(data: any): void;

	productName: string;
	productVersion: string;
	interfaceVersion: string;
	responsibleNmvo: string;
	usedNmvsEnvironment: string;
	plannedMarketIntroduction: string;
	additionalComments: string;

	companyName: string;
	companyAddress: string;
	firstName: string;
	lastName: string;
	position: string;
	address: string;
	email: string;
	phone: string;

	tc_g110_01_tester: string;
	tc_g110_01_date: string;
	tc_g110_01_passed: string;
	tc_g110_01_prod_relevant: string;
	tc_g110_01_comment: string;

	tc_g115_01_tester: string;
	tc_g115_01_date: string;
	tc_g115_01_passed: string;
	tc_g115_01_prod_relevant: string;
	tc_g115_01_comment: string;

	tc_g120_01_tester: string;
	tc_g120_01_date: string;
	tc_g120_01_passed: string;
	tc_g120_01_prod_relevant: string;
	tc_g120_01_comment: string;

	tc_g125_01_tester: string;
	tc_g125_01_date: string;
	tc_g125_01_passed: string;
	tc_g125_01_prod_relevant: string;
	tc_g125_01_comment: string;

	tc_g130_01_tester: string;
	tc_g130_01_date: string;
	tc_g130_01_passed: string;
	tc_g130_01_prod_relevant: string;
	tc_g130_01_comment: string;

	tc_g135_01_tester: string;
	tc_g135_01_date: string;
	tc_g135_01_passed: string;
	tc_g135_01_prod_relevant: string;
	tc_g135_01_comment: string;

	tc_g140_01_tester: string;
	tc_g140_01_date: string;
	tc_g140_01_passed: string;
	tc_g140_01_prod_relevant: string;
	tc_g140_01_comment: string;

	tc_g145_01_tester: string;
	tc_g145_01_date: string;
	tc_g145_01_passed: string;
	tc_g145_01_prod_relevant: string;
	tc_g145_01_comment: string;

	tc_g150_01_tester: string;
	tc_g150_01_date: string;
	tc_g150_01_passed: string;
	tc_g150_01_prod_relevant: string;
	tc_g150_01_comment: string;

	tc_g155_01_tester: string;
	tc_g155_01_date: string;
	tc_g155_01_passed: string;
	tc_g155_01_prod_relevant: string;
	tc_g155_01_comment: string;

	tc_g160_01_tester: string;
	tc_g160_01_date: string;
	tc_g160_01_passed: string;
	tc_g160_01_prod_relevant: string;
	tc_g160_01_comment: string;

	tc_g165_01_tester: string;
	tc_g165_01_date: string;
	tc_g165_01_passed: string;
	tc_g165_01_prod_relevant: string;
	tc_g165_01_comment: string;

	tc_g170_01_tester: string;
	tc_g170_01_date: string;
	tc_g170_01_passed: string;
	tc_g170_01_prod_relevant: string;
	tc_g170_01_comment: string;

	tc_g175_01_tester: string;
	tc_g175_01_date: string;
	tc_g175_01_passed: string;
	tc_g175_01_prod_relevant: string;
	tc_g175_01_comment: string;

	tc_g180_01_tester: string;
	tc_g180_01_date: string;
	tc_g180_01_passed: string;
	tc_g180_01_prod_relevant: string;
	tc_g180_01_comment: string;

	tc_g185_01_tester: string;
	tc_g185_01_date: string;
	tc_g185_01_passed: string;
	tc_g185_01_prod_relevant: string;
	tc_g185_01_comment: string;

	tc_g195_01_tester: string;
	tc_g195_01_date: string;
	tc_g195_01_passed: string;
	tc_g195_01_prod_relevant: string;
	tc_g195_01_comment: string;

	recommendations: string;

	approver_print_name: string;
	approver_title: string;
	approver_role: string;
	approver_date: string;
	approver_signature: string;

	testCaseProtocol: {
		testCaseNumber: string;
		testCaseDescription: string;
		applicableInProdEnvironments: string;
		testDate: string;
		systemDate: string;
		tester: string;
		testCaseResult: string;
		arvatoIncidentNumber: string;

		setupProcedure: string;
		hardware: string;
		additionalSoftware: string;
		proceduralRequirements: string;

		testPackUsed: string;
		serialNumberUsed: string;
		proceduralSteps: string;

		result: string;

		comments: string;
	}[];
};

// const { persist, purge } = configurePersist({
// 	rootKey: 'nmvsSwsPortal_v2',
// 	storage: localStorage,
// });

export const useBaselineProtocolFormStore = create<BaselineProtocolFormStoreProps>()(
	persist(
		immer(set => ({
			set: fn => set(fn),
			productName: '',
			productVersion: '',
			interfaceVersion: '',
			responsibleNmvo: '',
			usedNmvsEnvironment: '',
			plannedMarketIntroduction: '',
			additionalComments: '',

			companyName: '',
			companyAddress: '',
			firstName: '',
			lastName: '',
			position: '',
			address: '',
			email: '',
			phone: '',

			tc_g110_01_tester: '',
			tc_g110_01_date: '',
			tc_g110_01_passed: '',
			tc_g110_01_prod_relevant: '',
			tc_g110_01_comment: '',

			tc_g115_01_tester: '',
			tc_g115_01_date: '',
			tc_g115_01_passed: '',
			tc_g115_01_prod_relevant: '',
			tc_g115_01_comment: '',

			tc_g120_01_tester: '',
			tc_g120_01_date: '',
			tc_g120_01_passed: '',
			tc_g120_01_prod_relevant: '',
			tc_g120_01_comment: '',

			tc_g125_01_tester: '',
			tc_g125_01_date: '',
			tc_g125_01_passed: '',
			tc_g125_01_prod_relevant: '',
			tc_g125_01_comment: '',

			tc_g130_01_tester: '',
			tc_g130_01_date: '',
			tc_g130_01_passed: '',
			tc_g130_01_prod_relevant: '',
			tc_g130_01_comment: '',

			tc_g135_01_tester: '',
			tc_g135_01_date: '',
			tc_g135_01_passed: '',
			tc_g135_01_prod_relevant: '',
			tc_g135_01_comment: '',

			tc_g140_01_tester: '',
			tc_g140_01_date: '',
			tc_g140_01_passed: '',
			tc_g140_01_prod_relevant: '',
			tc_g140_01_comment: '',

			tc_g145_01_tester: '',
			tc_g145_01_date: '',
			tc_g145_01_passed: '',
			tc_g145_01_prod_relevant: '',
			tc_g145_01_comment: '',

			tc_g150_01_tester: '',
			tc_g150_01_date: '',
			tc_g150_01_passed: '',
			tc_g150_01_prod_relevant: '',
			tc_g150_01_comment: '',

			tc_g155_01_tester: '',
			tc_g155_01_date: '',
			tc_g155_01_passed: '',
			tc_g155_01_prod_relevant: '',
			tc_g155_01_comment: '',

			tc_g160_01_tester: '',
			tc_g160_01_date: '',
			tc_g160_01_passed: '',
			tc_g160_01_prod_relevant: '',
			tc_g160_01_comment: '',

			tc_g165_01_tester: '',
			tc_g165_01_date: '',
			tc_g165_01_passed: '',
			tc_g165_01_prod_relevant: '',
			tc_g165_01_comment: '',

			tc_g170_01_tester: '',
			tc_g170_01_date: '',
			tc_g170_01_passed: '',
			tc_g170_01_prod_relevant: '',
			tc_g170_01_comment: '',

			tc_g175_01_tester: '',
			tc_g175_01_date: '',
			tc_g175_01_passed: '',
			tc_g175_01_prod_relevant: '',
			tc_g175_01_comment: '',

			tc_g180_01_tester: '',
			tc_g180_01_date: '',
			tc_g180_01_passed: '',
			tc_g180_01_prod_relevant: '',
			tc_g180_01_comment: '',

			tc_g185_01_tester: '',
			tc_g185_01_date: '',
			tc_g185_01_passed: '',
			tc_g185_01_prod_relevant: '',
			tc_g185_01_comment: '',

			tc_g195_01_tester: '',
			tc_g195_01_date: '',
			tc_g195_01_passed: '',
			tc_g195_01_prod_relevant: '',
			tc_g195_01_comment: '',

			recommendations: '',

			approver_print_name: '',
			approver_title: '',
			approver_role: '',
			approver_date: '',
			approver_signature: '',

			testCaseProtocol: [
				{
					testCaseNumber: '',
					testCaseDescription: '',
					applicableInProdEnvironments: '',
					testDate: '',
					systemDate: '',
					tester: '',
					testCaseResult: '',
					arvatoIncidentNumber: '',

					setupProcedure: '',
					hardware: '',
					additionalSoftware: '',
					proceduralRequirements: '',

					testPackUsed: '',
					serialNumberUsed: '',
					proceduralSteps: '',

					result: '',

					comments: '',
				},
			],
		})),
		{
			name: 'nmvsSwsPortal_v3_baseline_protocol', // name of item in the storage (must be unique)
			storage: createJSONStorage(() => sessionStorage),
			partialize: state => ({
				productName: state.productName,
				productVersion: state.productVersion,
				// further fields to be persisted
			}), // allow only specific fields
		}
	)
);
