/* eslint-disable no-console */
/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Polyfills
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'reflect-metadata';

// Global styles
import './styles/global.scss';
import './utils/mobile-scrollbar-detection';

// Dependencies
// import * as serviceWorker from 'serviceWorker';
import * as Sentry from '@sentry/react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Analytics from 'analytics';
import { AuthStore, AuthStoreProvider } from 'auth';
import FontFaceObserver from 'fontfaceobserver';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router5';
import { FocusStyleManager } from '@blueprintjs/core';
import reportWebVitals from 'reportWebVitals';
import { Router } from 'router';
import { ApiService, HttpService, NmvsAnalyticsProviderPlugin, PusherProvider } from 'services';
import { RootStore, RootStoreProvider } from 'stores';
import { ErrorFallback } from 'app/components/common/ErrorFallback';
import { Toaster } from 'app/components/common/Toaster/Toaster';
import { AnalyticsProvider } from 'use-analytics';

// Import root app
import App from 'app';

// Initialize languages
import { i18next } from './locales/i18n';

// Configure scrolling
(window as Window & { noZensmooth: boolean }).noZensmooth = true;
// eslint-disable-next-line import/first
import 'zenscroll';

// disable console log for production
(function () {
	const noop = function () {};
	if (import.meta.env.PROD) {
		console.log = noop;
		console.warn = noop;
		console.error = noop;
		console.info = noop;
		console.debug = noop;
	}
})();

// Configure sentry
Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [Sentry.replayIntegration()],
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	tracesSampleRate: 1.0,

	// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ['localhost', /^https:\/\/sws-nmvs\.eu/, /^https:\/\/dev\.sws-nmvs\.eu/],

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});
Sentry.setTag('app_name', `sws-portal-fe-${import.meta.env.PROD ? 'prod' : 'dev'}`);
Sentry.setTag('app_type', 'frontend');
Sentry.setTag('app_env', import.meta.env.MODE);

// Observe loading of Open Sans (to remove 'Open Sans', remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Open Sans', {});

// When Roboto is loaded, add a font-family using Roboto to the body
openSansObserver.load(null, 6000).then(
	() => {
		document.body.classList.add('fontLoaded');
		console.log('Open Sans font is loaded');
	},
	() => {
		console.log('Open Sans font is not available');
	}
);

// Set up authStore -> httpService -> apiService, then set apiService for authStore to avoid circular dependency
const authStore = new AuthStore();
const httpService = new HttpService(authStore);
const apiService = new ApiService(httpService);

// Set up root store
const rootStore = RootStore.create({}, { api: apiService });

// Create a query client
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			structuralSharing: false,
		},
	},
});

// Set up router
const router = new Router(authStore, rootStore);

// Set up analytics
const analytics = Analytics({
	app: 'nmvs-sws-portal-fe',
	plugins: [
		NmvsAnalyticsProviderPlugin(apiService, {
			enabled: true,
			debug: false,
		}),
	],
});

// Enable Focus management
// https://blueprintjs.com/docs/#core/accessibility
FocusStyleManager.onlyShowFocusOnTabs();

// const store = configureAppStore();
const root = createRoot(document.getElementById('root')!);

root.render(
	<Sentry.ErrorBoundary fallback={<ErrorFallback />}>
		<AuthStoreProvider authStore={authStore}>
			<RouterProvider router={router.router5}>
				<QueryClientProvider client={queryClient}>
					<AnalyticsProvider instance={analytics}>
						<RootStoreProvider rootStore={rootStore}>
							<PusherProvider>
								<HelmetProvider>
									<StrictMode>
										<Toaster>
											<App />
										</Toaster>
									</StrictMode>
								</HelmetProvider>
							</PusherProvider>
						</RootStoreProvider>
					</AnalyticsProvider>
				</QueryClientProvider>
			</RouterProvider>
		</AuthStoreProvider>
	</Sentry.ErrorBoundary>
);

// Hot reload-able translation json files
if (import.meta.hot) {
	import.meta.hot.on('locales-update', () => {
		i18next.reloadResources().then(() => {
			i18next.changeLanguage(i18next.language);
		});
	});
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.debug);
