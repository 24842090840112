//@ts-nocheck
import { PluginFactory, State, Router as Router5, errorCodes } from 'router5';
import { Router } from '../Router';

// Tell MobX which page we're on
export function mobxRouterPluginFactory(router: Router) {
	const mobxRouterPlugin: PluginFactory = (router5: Router5, deps) => {
		return {
			onTransitionError: (
				toState: State,
				fromState: State,
				error: Error | { code: string; redirect?: { name: string } }
			) => {
				if ((error as { code: string }).code === errorCodes.SAME_STATES) {
					// use case: /login -> redirect -> /home -> user back -> url is /login again -> same state error
					//    -> replace to /home again to fix the url bar
					router5.replaceHistoryState(toState.name, toState.params);
				}
			},
			onTransitionSuccess: (toState: State) => {
				router.setCurrent(toState);
			},
		};
	};

	return mobxRouterPlugin;
}
