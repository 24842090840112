/* eslint-disable no-console */
import { toJS } from 'mobx';
import { cast, flow, getParent, Instance, types } from 'mobx-state-tree';
import { RootStore } from 'stores';
import { CreateBaselinePackageDto } from 'utils/dtos/create-baseline-package.dto';
import { CreateBaselineProtocolDto } from 'utils/dtos/create-baseline-protocol.dto';
import { ObservableState } from 'types/app.typings';

const BaselineTestPackage = types.model('BaselineTestPackage', {
	id: types.string,
	url: types.string,
	createdAt: types.string,
	testCaseName: types.string,
	productName: types.string,
	productVersion: types.string,
	testCaseDescription: types.string,
	endCustomers: types.array(types.string),
});

const BaselineTestResult = types.model('BaselineTestResult', {
	extTrxId: types.string,
	returnCode: types.string,
	businessProcessId: types.string,
	inputData: types.string,
	outputData: types.string,
	startDate: types.Date,
});

const EnhancedBaselineTestItem = types.model('EnhancedBaselineTestItem', {
	id: types.string,
	resultReceivedAt: types.frozen(types.union(types.string, types.null, types.undefined)),
	lastProtocolUploadedAt: types.frozen(types.union(types.string, types.null, types.undefined)),
	testProtocolUploadedAt: types.frozen(types.union(types.string, types.null, types.undefined)),
	protocolUploadCount: types.number,
	testProtocolUri: types.frozen(types.union(types.string, types.null, types.undefined)),
	testProtocolStorageFilename: types.frozen(types.union(types.string, types.null, types.undefined)),
	updatedAt: types.string,
	createdAt: types.string,
	nmvs_UserName: types.string,
	swsUser: types.frozen<{
		id: string;
		email: string;
		firstName: string;
		lastName: string;
		companyName: string;
	}>(),
	productName: types.string,
	productVersion: types.string,
	country: types.frozen<{
		code: string;
	}>(),
	certificationStatus: types.string,
	baselineTestPackage: types.frozen<{
		id: string;
		productName: string;
		productVersion: string;
		nmvs_UserName: string;
		endCustomers: string[];
		testCaseName: string;
		testCaseDescription: string;
		updatedAt: string;
		createdAt: string;
	}>(),
	certificationInitiatedAt: types.union(types.string, types.null, types.undefined),
	lastProtocolFilename: types.union(types.string, types.null, types.undefined),
	expanded: types.boolean,
	loading: types.boolean,
	error: types.boolean,
	items: types.array(BaselineTestResult),
});

export type EnhancedBaselineTestItemInstance = Instance<typeof EnhancedBaselineTestItem>;
export type BaselineTestResultInstance = Instance<typeof BaselineTestResult>;
export type BaselineTestPackageInstance = Instance<typeof BaselineTestPackage>;

export const BaselineStore = types
	.model('BaselineStore', {
		lastUpdated: types.number,
		docs: types.array(EnhancedBaselineTestItem),
		error: types.maybeNull(types.frozen()),
		state: types.enumeration(Object.values(ObservableState)),
		_uploadingItems: types.array(types.string),
		_finishedItems: types.array(types.string),
		_failedItems: types.array(types.string),
		_opState: types.enumeration(Object.values(ObservableState)),
		_uploadProtocolState: types.enumeration(Object.values(ObservableState)),
		_submitProtocolState: types.enumeration(Object.values(ObservableState)),
		_uploadProtocolError: types.maybeNull(types.frozen()),
		_submitProtocolError: types.maybeNull(types.frozen()),
		_testResult: types.maybeNull(BaselineTestResult),
		_baselineDocumentation: types.maybeNull(types.frozen()),
		_testPackage: types.maybeNull(BaselineTestPackage),
		_singleBaselineStatus: types.maybeNull(types.frozen()),
	})
	.views(self => ({
		get isLoading() {
			return self.state === ObservableState.PENDING;
		},
		get isOpLoading() {
			return self._opState === ObservableState.PENDING;
		},
		get isUploadingProtocol() {
			return self._uploadProtocolState === ObservableState.PENDING;
		},
		get isSubmittingProtocol() {
			return self._submitProtocolState === ObservableState.PENDING;
		},
		get hasError() {
			return self.state === ObservableState.ERROR;
		},
		get hasOpError() {
			return self._opState === ObservableState.ERROR;
		},
		get hasUploadError() {
			return self._uploadProtocolState === ObservableState.ERROR;
		},
		get hasSubmitProtocolError() {
			return self._submitProtocolState === ObservableState.ERROR;
		},
		get docList(): any {
			return toJS(self.docs);
		},
		get uploadingItems() {
			return toJS(self._uploadingItems);
		},
		get finishedItems() {
			return toJS(self._finishedItems);
		},
		get failedItems() {
			return toJS(self._failedItems);
		},
		get testResult() {
			return self._testResult;
		},
		get singleBaselineTestStatus() {
			return self._singleBaselineStatus;
		},
		get baselineDocumentation() {
			return self._baselineDocumentation;
		},
		get createdTestPackage() {
			return self._testPackage;
		},
		get lastChanged() {
			return self.lastUpdated;
		},
		get rootStore(): any {
			return getParent<typeof RootStore>(self);
		},
		get promiseState() {
			return self.state;
		},
		get uploadProtocolState() {
			return self._uploadProtocolState;
		},
		get submitProtocolState() {
			return self._submitProtocolState;
		},
	}))
	.actions(self => ({
		process(data: any): any {
			// const root: RootStoreType = getRoot(self);
			self.lastUpdated = Date.now();
			const enhancedData = data.map((elem: Record<string, any>) => ({
				...elem,
				expanded: false,
				loading: false,
				error: false,
				items: [],
			}));
			self.docs = enhancedData;
			self.lastUpdated = Date.now(); // use to force rerender of baseline test result list
			self.state = ObservableState.DONE;
			return enhancedData;
		},
	}))
	.actions(self => ({
		fetchDocs: flow(function* () {
			self.state = ObservableState.PENDING;
			try {
				const docs = yield self.rootStore.api.getBaselineStatuses();
				return self.process(docs);
			} catch (e: any) {
				console.error('Unable to fetch baseline tests', e);
				self.error = e;
				self.lastUpdated = Date.now(); // use to force rerender of baseline test result list
				self.state = ObservableState.ERROR;
			}
		}),
		fetchOneById: flow<void, [id: string]>(function* (id: string) {
			self.state = ObservableState.PENDING;
			try {
				self._singleBaselineStatus = yield self.rootStore.api.getTestStatusById(id);
				self.state = ObservableState.DONE;
			} catch (e: any) {
				console.error('Unable to fetch single baseline status', e);
				self.error = e;
				self.state = ObservableState.ERROR;
			}
		}),
		toggleResult: flow<void, [index: number]>(function* (index: number) {
			const testStatus = self.docs[index];
			if (testStatus) {
				try {
					if (testStatus.expanded) {
						self.lastUpdated = Date.now(); // use to force rerender of baseline test result list
						testStatus.expanded = false;
					} else {
						self.lastUpdated = Date.now(); // use to force rerender of baseline test result list
						testStatus.expanded = true;
						testStatus.loading = true;
						testStatus.error = false;
						self.state = ObservableState.PENDING;
						const data = yield self.rootStore.api.getBaselineTestResult(testStatus.baselineTestPackage.id);
						testStatus.loading = false;
						self.lastUpdated = Date.now(); // use to force rerender of baseline test result list
						testStatus.items = data;
						self.state = ObservableState.DONE;
					}
				} catch (e: any) {
					console.error('Unable to fetch baseline tests', e);
					self.lastUpdated = Date.now(); // use to force rerender of baseline test result list
					self.error = e;
					testStatus.loading = false;
					testStatus.error = true;
					testStatus.items = [] as any;
					self.state = ObservableState.ERROR;
				}
			}
		}),
		getBaselineDocs: flow<void, []>(function* () {
			self._opState = ObservableState.PENDING;
			try {
				self._baselineDocumentation = yield self.rootStore.api.getBaselineDocs();
				self.lastUpdated = Date.now(); // use to force rerender of baseline test result list
				self._opState = ObservableState.DONE;
			} catch (e: any) {
				console.error('Unable to get baseline documentation', e);
				self.lastUpdated = Date.now(); // use to force rerender of baseline test result list
				self.error = e;
				self._opState = ObservableState.ERROR;
			}
		}),
		uploadTestProtocol: flow<void, [data: FormData]>(function* (data: FormData) {
			self._uploadProtocolState = ObservableState.PENDING;
			// remove this baseline ID from failed and finished list
			const updatedFailedItems = toJS(self._failedItems).filter(item => item !== data.get('statusId'));
			self._failedItems = cast(updatedFailedItems);
			const updatedFinishedItems = toJS(self._finishedItems).filter(item => item !== data.get('statusId'));
			self._finishedItems = cast(updatedFinishedItems);
			// add this baseline ID to the uploading list
			self._uploadingItems = cast([...toJS(self._uploadingItems), data.get('statusId') as string]);
			try {
				yield self.rootStore.api.uploadBaselineTestProtocol(data);
				self.lastUpdated = Date.now(); // use to force rerender of baseline test result list
				// remove this baseline ID from the uploading list upon SUCCESS
				const updatedUploadingItems = toJS(self._uploadingItems).filter(item => item !== data.get('statusId'));
				self._uploadingItems = cast(updatedUploadingItems);
				// add this baseline ID to the finished list
				self._finishedItems = cast([...toJS(self._finishedItems), data.get('statusId') as string]);
				self._uploadProtocolState = ObservableState.DONE;
			} catch (e: any) {
				console.error('Unable to upload baseline test protocol', e);
				self.lastUpdated = Date.now(); // use to force rerender of baseline test result list
				// remove this baseline ID from the uploading and finished list upon FAILURE
				const updatedUploadingItems = toJS(self._uploadingItems).filter(item => item !== data.get('statusId'));
				self._uploadingItems = cast(updatedUploadingItems);
				const updatedFinishedItems = toJS(self._finishedItems).filter(item => item !== data.get('statusId'));
				self._finishedItems = cast(updatedFinishedItems);
				// add this baseline ID to the failed list
				self._failedItems = cast([...toJS(self._failedItems), data.get('statusId') as string]);
				self._uploadProtocolError = e;
				self._uploadProtocolState = ObservableState.ERROR;
			}
		}),
		submitTestProtocol: flow<void, [data: CreateBaselineProtocolDto]>(function* (data: CreateBaselineProtocolDto) {
			self._submitProtocolState = ObservableState.PENDING;
			try {
				yield self.rootStore.api.createBaselineProtocol(data);
				self._submitProtocolState = ObservableState.DONE;
			} catch (e: any) {
				console.error('Unable to submit baseline test protocol', e);
				self._submitProtocolError = e;
				self._submitProtocolState = ObservableState.ERROR;
			}
		}),
		createBaselinePackage: flow<void, [data: CreateBaselinePackageDto]>(function* (data: CreateBaselinePackageDto) {
			self._opState = ObservableState.PENDING;
			try {
				self._testPackage = yield self.rootStore.api.createBaselineTestPackage(data);
				self._opState = ObservableState.DONE;
			} catch (e: any) {
				console.error('Unable to create baseline test package', e);
				self.error = e;
				self._opState = ObservableState.ERROR;
			}
		}),
		clearCreatedTestPackage() {
			self._testPackage = null;
		},
		setTestResult(result: BaselineTestResultInstance | null) {
			self._testResult = result;
		},
		setUploadingItems(items: string[]) {
			self._uploadingItems = cast(items);
		},
		resetError() {
			self.error = null;
			self.state = ObservableState.INIT;
			self._opState = ObservableState.INIT;
			self.lastUpdated = 0; // use to force rerender of baseline test result list
		},
		resetOpState() {
			self.error = null;
			self._opState = ObservableState.INIT;
			self.lastUpdated = 0; // use to force rerender of baseline test result list
		},
		resetUploadState() {
			self._uploadProtocolError = null;
			self._uploadProtocolState = ObservableState.INIT;
		},
		resetSubmitProtocolState() {
			self._submitProtocolError = null;
			self._submitProtocolState = ObservableState.INIT;
		},
		resetStore() {
			self.error = null;
			self.state = ObservableState.INIT;
			self._opState = ObservableState.INIT;
			self.lastUpdated = 0;
			self._testResult = null;
			self._baselineDocumentation = null;
			self._uploadProtocolError = null;
			self._submitProtocolError = null;
			self._singleBaselineStatus = null;
			self._uploadProtocolState = ObservableState.INIT;
			self._submitProtocolState = ObservableState.INIT;
			self.docs = cast([]);
			self._uploadingItems = cast([]);
			self._finishedItems = cast([]);
			self._failedItems = cast([]);
		},
	}));

export interface BaselineStoreType extends Instance<typeof BaselineStore> {}
