import {
	CreateTestPackageContainerState,
	NewsAndFaq,
	ObservableState,
	SelectOption,
	UserProfile,
	UserSoftwareCountry,
} from 'types/app.typings';
import { createWithEqualityFn as create } from 'zustand/traditional';
import { StateCreator } from 'zustand';
import { createJSONStorage, persist, PersistOptions } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export type UiStoreProps = {
	set: (fn: (state: UiStoreProps) => void) => void;
	devInfo: {
		open: boolean;
	};
	testPage: {
		requestViewerDialog: {
			testResult: Record<string, any> | null;
		};
		createTestPackageContainer: {
			state: CreateTestPackageContainerState;
		};
	};
	baselinePage: {
		requestViewerDialog: {
			open: boolean;
		};
		createTestPackageContainer: {
			state: CreateTestPackageContainerState;
		};
		uploadTestProtocolContainer: {
			showFileScanAlert: boolean;
		};
	};
	footer: {
		phoneNumberTable: {
			open: boolean;
		};
	};
	userArea: {
		open: boolean;
		submitted: boolean;
		isTableOpen: boolean;
		userSWCountries: UserSoftwareCountry[] | null;
		newsletterSubscribed: boolean;
		userProfile: UserProfile | null;
	};
	landingPage: {
		registrationForm: {
			submitting: boolean;
			submitState: ObservableState;
		};
		newsDisplay: {
			showDetails: boolean;
			newsItem: NewsAndFaq | null;
		};
	};
	documentPage: {
		selectedRelease: string;
		selectedReleaseOption: SelectOption | null;
	};
	faqPage: {
		page: number;
		size: number;
		search: string;
		activeCategories: string[];
	};
	stage: {
		videoOpen: boolean;
	};
};

export const useUiStore = create<UiStoreProps>()(
	persist(
		immer(set => ({
			set: fn => set(fn),
			devInfo: {
				open: true,
			},
			baselinePage: {
				requestViewerDialog: {
					open: false,
				},
				createTestPackageContainer: {
					state: CreateTestPackageContainerState.INITIAL,
				},
				uploadTestProtocolContainer: {
					showFileScanAlert: false,
				},
			},
			testPage: {
				requestViewerDialog: {
					testResult: null,
				},
				createTestPackageContainer: {
					state: CreateTestPackageContainerState.INITIAL,
				},
			},
			footer: {
				phoneNumberTable: {
					open: false,
				},
			},
			userArea: {
				open: false,
				submitted: false,
				isTableOpen: false,
				userSWCountries: null,
				newsletterSubscribed: false,
				userProfile: null,
			},
			landingPage: {
				registrationForm: {
					submitting: false,
					submitState: ObservableState.INIT,
				},
				newsDisplay: {
					showDetails: false,
					newsItem: null,
				},
			},
			documentPage: {
				selectedRelease: '',
				selectedReleaseOption: null,
			},
			faqPage: {
				page: 1,
				size: 5,
				search: '',
				activeCategories: [],
			},
			stage: {
				videoOpen: false,
			},
		})),
		{
			name: 'nmvsSwsPortal_v2_ui', // name of item in the storage (must be unique)
			storage: createJSONStorage(() => sessionStorage),
			partialize: state => ({
				devInfo: {
					open: state.devInfo.open,
				},
			}), // allow only specific fields
		} as PersistOptions<UiStoreProps>
	) as StateCreator<UiStoreProps>
);
