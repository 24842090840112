/**
 *
 * Asynchronously loads the component for TermsAndConditionsPage
 *
 */

import { lazyLoad } from 'utils/loadable';

const TermsAndConditionsPage = lazyLoad(
	() => import('./index'),
	module => module.TermsAndConditionsPage
);

export default TermsAndConditionsPage;
