export enum RouteMap {
	Home = 'portal',
	Login = 'login',
	DocumentationPage = 'documentation_page',
	NamsDocumentationPage = 'nams_documentation_page',
	TestingPage = 'testing_page',
	BaselineTestingPage = 'baseline_testing_page',
	BaselineProtocolPage = 'baseline_protocol_page',
	FaqPage = 'faq_page',
	RegistrationPage = 'registration_page',
	ResetPasswordPage = 'password_reset_page',
	ConfirmRegistrationPage = 'confirm_registration_page',
	ToCPage = 'toc_page',
	DataProtectionGDPR = 'gdpr_page',
	ImprintPage = 'imprint_page',
	UserProfilePage = 'user_profile_page',
	TutorialPage = 'tutorials_page',
}
