import { IsNotEmpty, IsOptional } from 'class-validator';

export class UserProfileDto {
	@IsNotEmpty() email!: string;

	@IsNotEmpty() salutation!: string;

	@IsNotEmpty() firstName!: string;

	@IsNotEmpty() lastName!: string;

	@IsNotEmpty() companyName!: string;

	@IsNotEmpty() phone!: string;

	@IsOptional() website?: string | null;

	@IsNotEmpty() address!: Record<string, any>;

	@IsNotEmpty() software!: Record<string, any>[];

	@IsNotEmpty() softwareCountry!: string[];

	@IsOptional() softwareCountrySupplements?: Record<string, any>;

	@IsNotEmpty() activityAreas!: string[];

	@IsOptional() activityAreaPharmacyRemark?: string | null;

	@IsOptional() activityAreaHospitalRemark?: string | null;

	@IsOptional() activityAreaWholesalerRemark?: string | null;

	@IsOptional() activityAreaOtherRemark?: string | null;

	@IsNotEmpty() organizationForm!: string;

	@IsOptional() keyClients?: string | null;

	@IsNotEmpty() concentrator!: boolean;

	@IsNotEmpty() activateNewsletter!: boolean;

	@IsOptional() nmvs_UserName?: string | null;

	@IsOptional() nmvs_Sandbox2_UserName?: string | null;

	@IsOptional() nmvsUserNameCreatedAt?: string | null;

	@IsOptional() nmvsSandbox2UserNameCreatedAt?: string | null;

	@IsOptional() lastSignIn?: string | null;

	@IsNotEmpty() createdAt!: string;
}
