import { PluginFactory, State } from 'router5';
import zenscroll from 'zenscroll';

// scroll after page transitions
export function scrollToPluginFactory(delay: number = 300) {
	const scrollToElementPlugin: PluginFactory = () => {
		let timer: null | NodeJS.Timeout = null;

		return {
			onTransitionStart() {
				if (timer != null) {
					clearTimeout(timer);
					timer = null;
				}
			},
			onTransitionSuccess(toState: State, fromState: State) {
				if ((toState && !fromState) || toState.path !== fromState.path) {
					const hash = window.location.hash;
					// check if we have a possible element that we can scroll later to
					if (hash) {
						// timeout is needed, as rendering may take a while
						timer = setTimeout(() => {
							timer = null;
							const el = document.getElementById(window.location.href.split('#')[1]);
							if (el) {
								// element found -> scroll to element
								zenscroll.to(el);
							} else {
								// no element -> scroll to top
								zenscroll.toY(0);
							}
						}, delay);
					} else {
						timer = setTimeout(() => {
							// there is no element, just go to top
							zenscroll.toY(0);
						}, delay);
					}
				}
			},
		};
	};

	return scrollToElementPlugin;
}
