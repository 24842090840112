import { FC, useState } from 'react';
import { ToasterContext, ToasterContextProps } from './toaster.context';

type Props = {
	value: ToasterContextProps;
	children?: any;
};

export const ToasterProvider: FC<Props> = ({ children, value }) => {
	const [data] = useState(() => value);

	return <ToasterContext.Provider value={data}>{children}</ToasterContext.Provider>;
};
