/**
 *
 * Asynchronously loads the component for ForgotPasswordPage
 *
 */

import { lazyLoad } from 'utils/loadable';

const ForgotPasswordPage = lazyLoad(
	() => import('./index'),
	module => module.ForgotPasswordPage
);

export default ForgotPasswordPage;
